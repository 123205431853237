module.exports = {
    headline: "Service Packages comparison table",
    activePlanHint: "active plan",
    buttonTextUpgrade: "upgrade plan",
    buttonTextActive: "active plan",
    startingAt: " ",
    plusFee: "plus installation of the Smart Cloud connection device for",
    premiumText: "When purchasing an \"Aquarea+ Premium\" service package, you will receive \"Aquarea+ Smart\" until a Panasonic technician checks the installation and approves that the key technical requirements are fulfilled. In the case of not achieving these technical requirements, you will remain an \"Aquarea+ Smart\" customer and can enjoy the benefits of this package. You will be charged the \"Aquarea+ Smart\" price until the time your installation has been approved for \"Aquarea+ Premium\".",
    header: {
        plan: [
            {
                path: "/image/plan_cards/aquarea_service+_comfort.svg",
                alt: "Aquarea Service+ Smart"
            },
            {
                path: "/image/plan_cards/aquarea_service+_advanced.svg",
                alt: "Aquarea Service+ Advanced"
            },
            {
                path: "/image/plan_cards/aquarea_service+_exclusive.svg",
                alt: "Aquarea Service+ Exclusive"
            }
        ]
    },
    feature: {
        list: [{
            label: "Annual maintenance visit",
            included: [true, true, "doubletick"]
            },
            {
                label: "Exclusive telephone assistance 6 days a week",
                included: [true, true, true]
            },
            {
                label: "Technical assistance prioritization",
                included: [false, true, true]
            },
            {
                label: "Additional Years of Warranty",
                included: [false, true, "doubletick"]
            },
            {
                label: "Labor and travel included",
                included: [false, " ", true]
            },
            {
                label: "Remote performance through Aquarea Service Cloud",
                included: [false, true, true]
            },
            {
                label: "Monitoring and delivery of reports periodically",
                included: [false, true, true]
            },
            {
                label: "Response time in 24 hours. working days*",
                included: [false, " ", true]
            },
            {
                label: "Spare parts discount after the warranty period",
                included: [false, " ", "25%"]
            },
        ],
        includedIcon: {
            src: "/image/icons/check_circle.svg",
            alt: "Included in Package"
        }
    },
    advantages: "Benefits of a subscription",
    explanation: {
        text: "*You can check the availability of the official technical service in your area on the technical support telephone line, 900 82 87 87.",
        layout: " explanation"
    },
    planAbo: [
        {
            sku: "contract-heatpump-bronze",
            price: '25€',
            monthly: "<strong>per month (VAT included)</strong>",
            quarterly:"<strong>per quarter (VAT included)</strong>",
            annually:"<strong>annual (VAT included)</strong>"
        },
        {
            sku: "contract-heatpump-silver",
            price: '35€',
            monthly: "<strong>per month (VAT included)</strong>",
            quarterly:"<strong>per quarter (VAT included)</strong>",
            annually:"<strong>annual (VAT included)</strong>"
        },
        {
            sku: "contract-heatpump-gold",
            price: '48€',
            monthly: "<strong>per month (VAT included)</strong>",
            quarterly:"<strong>per quarter (VAT included)</strong>",
            annually:"<strong>annual (VAT included)</strong>"
        }
    ],
    productCode: [],
    annotation: " "
};
