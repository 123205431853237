module.exports = {
    protected: "Sie sind durch den Aquarea Service+ geschützt",
    heatPumpsCorrectly: "",
    callHotline: "Hotline anrufen",
    sendMail: "E-Mail senden",
    gotoCloud: "Zur Aquarea Smart Cloud",
    imageName: {
        yellow: "exclusive",
        green: "advanced",
        brown: "comfort",
        comfort: "Aquarea comfort"
    },
    imageTags:{
        exclusive: "Aquarea exklusiv",
        advance:"Aquarea advance",
        smart: "Aquarea smart"
    },
    downgradeInfo1: "Für Ihren Schutzplan ist eine Herabstufung vorgesehen auf ",
    downgradeInfo2: ". Dieses Downgrade wird nach Ablauf der aktuellen Vertragslaufzeit angewendet.",
    contactmail: "service-heizung@panasonic.com",
}
