module.exports = {
    headline: "Vergleichstabelle Servicepakete",
    activePlanHint: "Aktivplan",
    buttonTextUpgrade: "Upgradeplan",
    buttonTextActive: "Aktivplan",
    startingAt: " ",
    plusFee: "plus Installation des Smart Cloud-Verbindungsgeräts für",
    premiumText: "Beim Kauf eines Servicepakets „Aquarea+ Premium“ erhalten Sie „Aquarea+ Smart“, bis ein Panasonic-Techniker die Installation überprüft und die Erfüllung der wesentlichen technischen Voraussetzungen bestätigt. Sollten diese technischen Voraussetzungen nicht erfüllt sein, bleiben Sie „Aquarea+ Smart“-Kunde und können die Leistungen dieses Pakets nutzen. Bis zur Freigabe Ihrer Installation für „Aquarea+ Premium“ wird Ihnen der „Aquarea+ Smart“-Preis berechnet.",
    header: {
        plan: [
            {
                path: "/image/plan_cards/aquarea_service+_comfort.svg",
                alt: "Aquarea Service+ Smart"
            },
            {
                path: "/image/plan_cards/aquarea_service+_advanced.svg",
                alt: "Aquarea Service+ Advanced"
            },
            {
                path: "/image/plan_cards/aquarea_service+_exclusive.svg",
                alt: "Aquarea Service+ Exclusive"
            }
        ]
    },
    feature: {
        list: [{
            label: "Jährlicher Wartungsbesuch",
            included: [true, true, true]
            },
            {
                label: "Exklusive telefonische Unterstützung an 6 Tagen pro Woche",
                included: [true, true, true]
            },
            {
                label: "Priorisierung der technischen Unterstützung",
                included: [false, true, true]
            },
            {
                label: "Zusätzliche Jahre Garantie",
                included: [false, true, true]
            },
            {
                label: "Lohn- und Fahrtkosten inbegriffen",
                included: [false, true, true]
            },
            {
                label: "Fernwartung über die Aquarea Service Cloud",
                included: [false, true, true]
            },
            {
                label: "Überwachung und regelmäßige Bereitstellung von Berichten",
                included: [false, true, true]
            },
            {
                label: "Reaktionszeit innerhalb von 24 Stunden, an Arbeitstagen*",
                included: [false, true, true]
            },
            {
                label: "Ersatzteilrabatt nach Ablauf der Garantiezeit",
                included: [false, " ", "25%"]
            },
        ],
        includedIcon: {
            src: "/image/icons/check_circle.svg",
            alt: "Im Paket enthalten"
        }
    },
    advantages: "Vorteile eines Abonnements",
    explanation: {
        text: "Preis inkl. MwSt. (Monat)",
        layout: " text plan-comparison_table_feature_name"
    },
    planAbo: [
        {
            sku: "contract-heatpump-bronze",
            price: '25€',
            monthly: "<strong>pro Monat (inkl. MwSt.)</strong>",
            quarterly:"<strong>pro Quartal (inkl. MwSt.)</strong>",
            annually:"<strong></strong>"
        },
        {
            sku: "contract-heatpump-silver",
            price: '35€',
            monthly: "<strong>pro Monat (inkl. MwSt.)</strong>",
            quarterly:"<strong>pro Quartal (inkl. MwSt.)</strong>",
            annually:"<strong></strong>"
        },
        {
            sku: "contract-heatpump-gold",
            price: '48€',
            monthly: "<strong>pro Monat (inkl. MwSt.)</strong>",
            quarterly:"<strong>pro Quartal (inkl. MwSt.)</strong>",
            annually:"<strong></strong>"
        }
    ],
    productCode: [
        {
            code: "Produktcode",
            layout: " text plan-comparison_table_feature_name"
        },
        {
            code: "SVC-A2W-WARR-YR-S",
            layout: "_footer code text"
        },
        {
            code: "SVC-A2W-WARR-YR-A",
            layout: "_footer code text"
        },
        {
            code: "SVC-A2W-WARR-YR-P",
            layout: "_footer code text"
        },
    ],
    annotation: "*Es gelten die Allgemeinen Geschäftsbedingungen"
};
