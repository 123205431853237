module.exports = {
    headline: "Registrazione Unità a pompa di calore",
    date: {
        title: "Data di installazione della tua pompa di calore",
        name: "data di installazione"
    },
    model: {
        title: "Quale modello è installato?",
        name: "NOME MODELLO",
        img: {
            linkText: "DOVE TROVARE?",
            path: "/image/checkout/heatpump.png"
        },
        emptyOption: "-- Scegliere il modello --",
        options: [
            "Non lo so",
            "WH-MDC05H3E5",
            "WH-MDC05J3E5",
            "WH-MDC07H3E5",
            "WH-MDC07J3E5",
            "WH-MDC09H3E5",
            "WH-MDC09J3E5",
            "WH-MDC12H6E5",
            "WH-MDC16H6E5",
            "WH-MHF09G3E5",
            "WH-MHF09G3E8",
            "WH-MHF12G6E5",
            "WH-MHF12G9E8",
            "WH-MXC09H3E5",
            "WH-MXC09H3E8",
            "WH-MXC09J3E5",
            "WH-MXC09J3E8",
            "WH-MXC12H6E5",
            "WH-MXC12H9E8",
            "WH-MXC12J6E5",
            "WH-MXC12J9E8",
            "WH-MXC16H9E8",
            "WH-MXC16J9E8",
            "WH-UD03HE5-1",
            "WH-UD03JE5",
            "WH-UD05HE5",
            "WH-UD05HE5-1",
            "WH-UD05JE5",
            "WH-UD07HE5-1",
            "WH-UD07JE5",
            "WH-UD09HE5-1",
            "WH-UD09HE8",
            "WH-UD09JE5",
            "WH-UD09JE5-1",
            "WH-UD12HE5",
            "WH-UD12HE8",
            "WH-UD16HE5",
            "WH-UD16HE8",
            "WH-UDZ03KE5",
            "WH-UDZ05KE5",
            "WH-UDZ07KE5",
            "WH-UDZ09KE5",
            "WH-UDZ09KE8",
            "WH-UDZ12KE5",
            "WH-UDZ12KE8",
            "WH-UDZ16KE8",
            "WH-UH09FE5",
            "WH-UH12FE5",
            "WH-UH12FE8",
            "WH-UQ09HE8",
            "WH-UQ12HE8",
            "WH-UQ16HE8",
            "WH-UX09HE5",
            "WH-UX09HE8",
            "WH-UX12HE5",
            "WH-UX12HE8",
            "WH-UX16HE8",
            "WH-UXZ09KE5",
            "WH-UXZ09KE8",
            "WH-UXZ12KE5",
            "WH-UXZ12KE8",
            "WH-UXZ16KE8",
            "WH-WDG05LE5",
            "WH-WDG07LE5",
            "WH-WDG09LE5",
            "WH-WXG09ME8",
            "WH-WXG12ME8",
            "WH-WXG16ME8",
            "WH-WXG20ME8",
            "WH-WXG25ME8",
            "WH-WXG30ME8",
        ]
    },
    serialNumber: {
        name: "NUMERO DI SERIE",
        img: {
            linkText: "DOVE TROVARE?",
            path: "/image/checkout/heatpump.png",
        },
    },
    gatewayId: {
        title: "Se si dispone già dell'adattatore di rete, inserire l'ID del dispositivo",
        name: "DEVICE ID (OPZIONALE)",
        img: {
            linkText: "DOVE TROVARE?",
            path: "/image/checkout/deviceID.png"
        }
    },
    shippingAddress: {
        title: "Indirizzo dell'installazione",
        street: {
            label: "Indirizzo",
            name: "street",
        },
        zipcode: {
            label: "C.A.P.",
            name: "zipcode",
        },
        city: {
            label: "Città (Provincia)",
            name: "city",
        },
    },
    btn: {
        back: "TORNA AL MIO ACCOUNT",
        next: "SALVA"
    },
    errors: {
        futureDate: "la data di installazione è nel futuro",
        dateTooFarInPast: "la data di installazione è troppo presto",
        onlyDigits: "solo cifre consentite",
        lengthIs11: "la lunghezza dell'id del device deve essere 11",
        zipCode: "5 e solo numeri",
        serialMin: "Minimo 8 cifre",
        serialMax: "Massimo 20 cifre",
        requiredField: "Questo campo è obbligatorio",
        addressTooLong: "Massimo 30 caratteri",
        cityTooLong: "Massimo 35 caratteri"
    }
};
