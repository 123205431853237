module.exports = {
    editBtn: "BEARBEITEN",
    confirmBtn: "SERVICEPAKET KAUFEN",
    connectionCheck: "Verbindungsprüfung",
    accountInformation: "Kontoinformationen",
    paymentDetails: "Zahlungsdetails",
    installedBase: "Installierte Basis",
    monthly:"Monatlich",
    quarterly:"Vierteljährlich",
    annually:"Jährlich",
    yes: "Ja",
    no: "Nein",
    details: {
        monthlyPrice:"Monatliche Kosten",
        connectionFeePrice:"Einmalige Kosten für den Installationsservice",
        internetConnected: "INTERNET VERBUNDEN",
        internetReady: "INTERNET BEREIT",
        modelName: "Modell",
        serialNumber: "Seriennummer",
        installationDate: "Installationsdatum",
        gatewayId: "Gateway-ID",
        title: "titel",
        firstName: "Vorname",
        lastName: "Nachname",
        name: "NAME",
        email: "E-MAIL",
        address : "Adresse",
        zipCode : "POSTLEITZAHL",
        city: "STADT",
        phoneNumber: "Telefonnummer",
        phone : "TELEFON",
        paymentMethod: "ZAHLUNGSART"
    },
    confirm: "bestätigen"
}
