module.exports = {
    headline: "Zur Kasse",
    summary: {
        confirmation: {
            headline: "Bestätigung",
            subheadline: "Ausgewählter Plan"
        },
    },
    steps: [
        {
            label: "Verbindungsprüfung",
            href: "/de_AT/home/service_package",
            href_label: "ZURÜCK ZU DEN SERVICEPAKETEN",
            next_btn: "NÄCHSTER SCHRITT",
            continue_btn: "FORTFAHREN OHNE AQUAREA CLOUD",
            add_installation_items: {
                add_installation_btn: "AQUAREA CLOUD HINZUFÜGEN",
                add_installation_label: "Wichtige Information:",
                add_installation_text: 'Um Ihnen den vollen Nutzen aus diesem Servicepaket bieten zu können, ist es notwendig, dass Ihr Gerät mit dem Internet verbunden ist. Fügen Sie einfach unser Aquarea Cloud-Gerät CZ-TAW1 zu Ihrem Servicepaket hinzu, um diese Internetverbindung zu aktivieren.</br></br>Beim Kauf nehmen wir das Gerät automatisch in Ihren Servicevertrag auf und rufen Sie an, um einen Installationstermin zu vereinbaren.</br>',
                add_installation_text_comfort: 'Um die Vorteile unserer Wartungspläne voll nutzen zu können, muss Ihre Wärmepumpe mit dem Internet verbunden sein.</br></br>Um die Internetverbindung sicherzustellen, empfehlen wir, unseren Installationsservice zu bestellen. Dieser Service kostet einmalig ##price## und beinhaltet das CZ-TAW1-Gerät und dessen Installation.</br></br>Wenn Sie diesen Wartungsplan weiterhin wünschen, wird der Installationsservice automatisch zum Kaufvorgang hinzugefügt. Wir vereinbaren mit Ihnen telefonisch einen Termin für die Installation.',
            },
            change_plan_items: {
                change_plan_changeid: 2,
                change_plan_btn_sameid: "NÄCHSTER SCHRITT",
                change_plan_btn: "MEINE AUSWAHL AUF COMFORT ÄNDERN",
                change_plan_link: "",
                change_plan_label: "Wichtige Information:",
                change_plan_text: 'Leider können wir Ihnen dieses Servicepaket derzeit nicht anbieten, da Ihr Gerät keine Verbindung zum Internet herstellen kann. Um Ihnen den bestmöglichen Service bieten zu können, ändern wir Ihr ausgewähltes Servicepaket auf Aquarea Service+ Comfort.',
            },
            text: "Um Ihnen den optimalen Service zu bieten, müssen wir sicherstellen, dass Ihre Geräte mit dem Internet verbunden sind.",
            inputList: [
                {
                    label: "Ist Ihr Gerät mit dem Internet verbunden?",
                    width: "col",
                    name: "internetConnection",
                    startValue: true,
                    hint: {
                        label: "nicht sicher?",
                        text: "test",
                        img: "https://picsum.photos/400/200",
                        img_alt: ""
                    },
                    images: [
                        {path: "/image/checkout/CloudAdapter.jpg", type: "image"}
                    ],
                    inputs: {
                        checkboxes: [
                            {
                                label: "Ja",
                                key: "internetConnected",
                                defaultValue: true,
                                value: true,
                            },
                            {
                                label: "Nein",
                                key: "internetConnected",
                                defaultValue: false,
                                value: false,
                            }
                        ]
                    }
                },
                {
                    label: "Welche Art von Bedienfeld haben Sie?",
                    width: "col",
                    name: "unitForInternet",
                    startValue: true,
                    hint: {
                        label: "nicht sicher?",
                        text: "",
                        img: "",
                        img_alt: ""
                    },
                    images: [
                        {path: "/image/checkout/NewController.jpg", type: "label"},
                        {path: "/image/checkout/OldController.jpg", type: "label"},
                    ],
                    inputs: {
                        checkboxes: [
                            {
                                label: "Ja",
                                key: "internetReady",
                                defaultValue: false,
                                value: true
                            },
                            {
                                label: "Nein",
                                key: "internetReady",
                                defaultValue: false,
                                value: false
                            }
                        ]
                    }
                },
                {
                    label: "Ist Ihre Wärmepumpe jünger als 5 Jahre?",
                    width: "col",
                    name: "baseUnitAge",
                    startValue: true,
                    inputs: {
                        checkboxes: [
                            {
                                label: "Ja",
                                key: "baseUnitAge",
                                defaultValue: true,
                                value: true,
                            },
                            {
                                label: "Nein",
                                key: "baseUnitAge",
                                defaultValue: false,
                                value: false,
                            }
                        ]
                    }
                }
            ]
        },
        {
            label: "Kontoinformationen",
            href: "/service_package",
            href_label: "ZURÜCK",
            next_btn: "NÄCHSTER SCHRITT",
            inputList: [
                {
                    headline: "Neues Konto erstellen",
                    login: "ANMELDEN",
                    login_path: "",
                    login_label: "Bereits Benutzer?",
                    inputs: [
                        [{
                            label: "E-Mail",
                            name: "email",
                            placeholder: "",
                            type: "email",
                            validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH", "EMAIL"],
                            constraints: {minLength: 3, maxLength: 80},
                            width: "col-12 col-lg-6",
                            checkId: "email",
                            validation: "matching_email"
                        },
                            {
                                label: "E-Mail wiederholen",
                                name: "repeat-email",
                                placeholder: "E-Mail",
                                type: "email",
                                validators: ["REQUIRED", "COMPARE"],
                                validationMessages: {
                                    COMPARE: "Die E-Mail muss identisch sein",
                                },
                                constraints: {compareControl: "email"},
                                width: "col-12 col-lg-6",
                                checkId: "email",
                            }],
                        [{
                            label: 'Passwort <span>Min. 8 Zeichen, Zahlen und Buchstaben</span>',
                            name: "password",
                            placeholder: "",
                            type: "password",
                            validators: ["REQUIRED", "MIN_LENGTH","PASSWORD"],
                            constraints: {minLength: 8},
                            width: "col-12 col-lg-6",
                            checkId: "password",
                        },
                            {
                                label: "Passwort wiederholen",
                                name: "repeat-password",
                                placeholder: "Passwort",
                                type: "password",
                                validators: ["REQUIRED", "COMPARE"],
                                validationMessages: {
                                    COMPARE: "Das Passwort muss identisch sein",
                                },
                                constraints: {compareControl: "password", minLength: 8},
                                width: "col-12 col-lg-6",
                                checkId: "password",
                            }]
                    ]
                },
                {
                    headline: "Kontoadresse",
                    inputs: [
                        [{
                            label: "Vorname",
                            name: "firstName",
                            placeholder: "",
                            type: "text",
                            validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
                            constraints: {minLength: 2, maxLength: 19},
                            width: "col-12 col-lg-6",
                            validation: "required"
                        },
                            {
                                label: "Nachname",
                                name: "lastName",
                                placeholder: "",
                                validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
                                constraints: {minLength: 2, maxLength: 19},
                                type: "text",
                                width: "col-12 col-lg-6",
                                validation: "required"
                            }],
                        [{
                            label: "STRASSE",
                            name: "street",
                            placeholder: "",
                            type: "text",
                            validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
                            constraints: {minLength: 1, maxLength: 30},
                            width: "col"
                        }],
                        [{
                            label: "POSTLEITZAHL",
                            name: "zipCode",
                            placeholder: "",
                            validators: ["REQUIRED", "ZIPCODE"],
                            constraints: {length: 4},
                            type: "text",
                            width: "col-6"
                        },
                            {
                                label: "STADT",
                                name: "city",
                                placeholder: "",
                                validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
                                constraints: {minLength: 1, maxLength: 35},
                                type: "text",
                                width: "col-6"
                            },
                            {
                                label: "LAND <img src='/image/menu/locales/austria.jpg' style='width: 1.5em'/> <br> <span> DIESES SERVICEPAKET UND DIE BEDINGUNGEN GELTEN FÜR IM ANGEGEBENEN LAND INSTALLIERTE GERÄTE. IST IHRE WÄRMEPUMPE IN EINEM ANDEREN LAND INSTALLIERT, WÄHLEN SIE DAS LAND BITTE OBEN RECHTS AUF DER SEITE AUS. HIER WERDEN DIE LÄNDER ANGEGEBEN, IN DENEN AQUAREA SERVICE+ VERFÜGBAR IST.</span>",
                                name: "country",
                                type: "country",
                                width: "col-12"
                            }
                        ],
                        [{
                            label: "Telefonnummer",
                            name: "phoneNumber",
                            key: "phoneNumber",
                            placeholder: "",
                            validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
                            constraints: {minLength: 9, maxLength: 17},
                            type: "phone",
                            width: "col",
                            offset:4,
                        }]
                    ]
                },
            ],
        },
        {
            label: "Zahlungsdetails",
            href: "/service_package",
            href_label: "ZURÜCK",
            subheadline: "Vertragszahlungsmodell",
            per_month:"pro Monat",
            pay: "zahlen",
            monthly:"monatlich",
            quarterly:"vierteljährlich",
            annually:"jährlich",
            discount: {
              name: "Rabatt",
              placeholder: "Rabattcode eingeben",
              button: "anwenden",
              remove: "entfernen",
              invalidHeadline: "Der eingegebene Rabattcode ist ungültig",
              invalidHint: "Bitte überprüfen Sie Ihre Eingabe",
              success: "Ihr Rabattcode wurde akzeptiert und wird automatisch auf Ihren Einkauf angewendet. Bitte beachten Sie, dass erst nach dem Bezahlvorgang ein Abzug vom Gesamtbetrag der Zahlung sichtbar ist. Dort können Sie diesen Rabatt in Ihrem Zahlungsplan einsehen.",
              errors: {
                  tooLong: "Der eingegebene Rabattcode ist zu lang.",
                  invalidInput: "Kein Rabatt eingegeben"
              }
            },
            text: "Bitte wählen Sie Ihre Zahlungsart Die Zahlung wird nach dem Checkout abgeschlossen",
            disclaimer: "Beim Kauf eines Servicepakets „Aquarea+ Premium“ erhalten Sie „Aquarea+ Smart“, bis ein Panasonic-Techniker die Installation überprüft und die Erfüllung der wesentlichen technischen Voraussetzungen bestätigt. Sollten diese technischen Voraussetzungen nicht erfüllt sein, bleiben Sie „Aquarea+ Smart“-Kunde und können die Leistungen dieses Pakets nutzen. Bis zur Freigabe Ihrer Installation für „Aquarea+ Premium“ wird Ihnen der „Aquarea+ Smart“-Preis berechnet.",
            credit_secure: "Bitte beachten Sie, dass unsere Plattform nur Kreditkarten akzeptiert, die 3D Secure aktiviert haben.\n" +
                "3D Secure ist in den meisten Fällen ein zusätzlicher Schritt bei der Validierung des Zahlungsvorgangs, bei dem der Karteninhaber einen Code eingeben muss, der im Rahmen des Bezahlvorgangs an sein Mobiltelefon gesendet wurde.\n" +
                "Um Ihre Kreditkarte hierfür zu registrieren, wenden Sie sich bitte direkt an Ihre Bank oder Ihren Kreditkartenaussteller oder informieren Sie sich auf deren Website über weitere Anweisungen.\n" +
                "Alternativ können Sie mit PayPal bezahlen.\n",
            visa_secure: "/image/icons/checkout/visaSecure.png",
            visa_secure_alt: "visa secure",
            mastercard_id_check: "/image/icons/checkout/mastercardIdCheckBlack.png",
            mastercard_id_check_alt: "mastercard id check",
            agreement: "Ich bestätige, dass ich die <a href=\"/de_AT/home/terms_and_conditions\" target=\"_blank\">Allgemeinen Geschäftsbedingungen von Aquarea Service+</a> gelesen habe und ihnen zustimme, und dass mir die <a href=\"/de_AT/home/privacy_policy\" target=\"_blank\">Datenschutzrichtlinie von Aquarea Service+</a> bekannt ist.",
            policy: "Ich bitte ausdrücklich darum und stimme zu, dass Sie mit der Ausführung der vertraglich vereinbarten Dienstleistung vor Ablauf der Widerrufsfrist beginnen.\n Ich bin verpflichtet, alle Dienstleistungen, einschließlich Reparatur- und Wartungsleistungen, die vor Ausübung des 14-tägigen Widerrufsrechts erbracht wurden, zu bezahlen.\n",
            campaign: "Ich bin damit einverstanden, eine separate E-Mail von Treedom mit einem Code zum Abflachen meines Baums zu erhalten.",
            next_btn: "NÄCHSTER SCHRITT",
            inputList: [
                {
                    headline: "Zahlungsdetails eingeben",
                    inputs: [
                        {
                            label: "Zahlungsmethode",
                            name: "payment_opt",
                            type: "payment_opt",
                            payments: [
                                {
                                    id: "0",
                                    label: "Zahlungsmethode",
                                    key: "paymentOpt",
                                    value: "credit",
                                    image: "/image/icons/checkout/credit.png",
                                    isChecked: false
                                },
                                {
                                    id: "1",
                                    label: "Zahlungsmethode",
                                    key: "paymentOpt",
                                    value: "paypal",
                                    image: "/image/icons/checkout/paypal.png",
                                    isChecked: false
                                },
                            ],
                            width: "col"
                        },
                        {
                            label: "KARTENNUMMER",
                            name: "card_number",
                            placeholder: "",
                            type: "text",
                            width: "col"
                        },
                        {
                            label: "VERFALLSDATUM",
                            name: "expiration_date",
                            placeholder: "VERFALLSDATUM",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            label: "CVV",
                            name: "cvv",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            labels: [
                                {
                                    id: "datarights",
                                    label: "Klicken Sie hier, um zu bestätigen, dass Sie die Bedingungen der Aquarea Service+-Kundenvereinbarung gelesen haben und ihnen zustimmen.",
                                    name: "datarights",
                                    value: "",
                                    width: "col"
                                },
                            ],
                            type: "radio",
                            width: "col",
                        }
                    ]
                },
            ],
        },
        {
            label: "Bestätigung",
            href: "/service_package",
            href_label: "ZURÜCK",
            next_btn: "NÄCHSTER SCHRITT",
            inputList: [
                {
                    headline: "Neues Konto erstellen",
                    inputs: [
                        {
                            label: "E-MAIL",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            label: "Passwort",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        }
                    ]
                },
                {
                    headline: "Kontoadresse",
                    inputs: [
                        {
                            label: "KUNDENTYP",
                            name: "customer",
                            placeholder: "Private",
                            type: "select",
                            width: "col col-lg-6",
                            options: [
                                "Private",
                                "test1",
                                "test2",
                                "test3",
                                "test4",
                                "test5",
                                "test6"
                            ]
                        },
                        {
                            label: "Vorname",
                            name: "first_name",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            label: "Nachname",
                            name: "last_name",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            label: "STRASSE",
                            name: "street",
                            placeholder: "",
                            type: "text",
                            width: "col"
                        },
                        {
                            label: "POSTLEITZAHL",
                            name: "zipCode",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            label: "STADT",
                            name: "city",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            label: "Telefonnummer",
                            name: "phoneNumber",
                            placeholder: "",
                            type: "text",
                            width: "col"
                        }
                    ]
                }
            ],
        }
    ]
}

