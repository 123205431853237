module.exports = {
    inputs: {
        label: "E-Mail",
        id: "email",
        name: "email",
        placeholder: "Ihre E-Mail",
        type: "email"
    },
    success: "Passwort zurücksetzen angefordert, bitte schauen Sie in Ihren E-Mail-Posteingang!",
    subheadline:"Passwort vergessen",
    headline:"Passwort vergessen",
    submit: "Absenden"
}