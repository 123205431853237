import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom/cjs/react-router-dom.min";

import SimpleCheckbox from "../../registration/simpleCheckbox";
import Checkbox from "../../registration/checkbox";
import StepButtons from "../stepButtons";
import {
    ANONYMOUS_ID_SESSION_STORAGE_NAME,
    CART_ID_SESSION_STORAGE_NAME,
    COUNTRY_CODE_NAME,
    getSessionStorageItem,
    setSessionStorageItem
} from "../../../lib/sessionStorage";
import {changeCartContract} from "../../../lib/dataAccess/checkout/cartRestEndpoint";
import {ErrorResponseHandling} from "../../../lib/dataAccess/errorResponseHandling";
import {CartContext} from "../../../lib/contexts/cartContext";
import {FormFieldset, FormHeadline, FormInfoBox, FormInfoBoxHeadline, FormRow, FormText} from "../../layout/form";
import FormItem from "../../registration/formItem";
import {scrollToPos} from "../../../lib/scrollToPos";
import {ProductContext} from "../../../lib/contexts/productContext";
import {AuthContext} from "../../../lib/contexts/authContext";
import {InstallationFeeContext} from "../../../lib/contexts/installationFeeContext";
import { formatMoneyByCountryCode } from "../../../lib/helper/moneyHelper";

const countryCode = getSessionStorageItem(COUNTRY_CODE_NAME) || 'en_IE';

const ConnectionCheckStep = ({navigation, session, currentStepValues, currentStep, currentSessionValues,}) => {

    const {installationFeeContext} = useContext(InstallationFeeContext);
    const {cartContext, setCartContext} = useContext(CartContext);
    const {setProductContext} = useContext(ProductContext);
    const { authContext, setAuthContext } = useContext(AuthContext);

    const [wasSelectedPlanChanged, setWasSelectedPlanChanged] = useState(false);
    const [internetConnected, setInternetConnected] = useState(true);
    const [internetReady, setInternetReady] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const history = useHistory();

    // check if values are exist set to current state
    useEffect(() => {
        if (checkIfCartWithBaseUnitExist()) {
            setUpdateValuesForConnectionCart();
        } else {
            setCheckboxValues(true, 'baseUnitAge');
            setCheckboxValues(true, 'internetConnected');
            setCheckboxValues(null, 'internetReady');
            session.updateValuesForConnection('addConnectionService', null, "Add Connection Service: ", currentStep);
        }
    }, [cartContext, authContext]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        scrollToPos(0, 0);
    }, [])



    const setCheckboxValues = (value, key) => {
        if (key === 'internetConnected') {
            setInternetConnected(value);
            currentStepValues.inputList[0].inputs.checkboxes[0].defaultValue = value;
            currentStepValues.inputList[0].inputs.checkboxes[1].defaultValue = !value;
            session.updateValuesForConnection(key, value, "Internet connect: ", currentStep);
            //has to be internetReady when connected
            if (value === true) {
                setCheckboxValues(true, 'internetReady');
            }
            if (value === false) {
                setCheckboxValues(null, 'internetReady');
            }
        }
        if (key === 'internetReady') {
            if (value == null) {
                currentStepValues.inputList[1].inputs.checkboxes[0].defaultValue = false;
                currentStepValues.inputList[1].inputs.checkboxes[1].defaultValue = false;
            } else {     
                currentStepValues.inputList[1].inputs.checkboxes[0].defaultValue = value;
                currentStepValues.inputList[1].inputs.checkboxes[1].defaultValue = !value;
            }
            setInternetReady(value);
            session.updateValuesForConnection(key, value, "Internet ready: ", currentStep);
        }
        if (key === 'baseUnitAge') {
            currentStepValues.inputList[2].inputs.checkboxes[0].defaultValue = value;
            currentStepValues.inputList[2].inputs.checkboxes[1].defaultValue = !value;
            session.updateValuesForConnection(key, value, "Younger than 5 years: ", currentStep);
        }
    }

    function checkIfCartWithBaseUnitExist() {
        return cartContext?.lineItems?.length > 0 && cartContext?.lineItems?.find(lineItem => lineItem?.sku.startsWith("contract-"))?.installedBaseUnitId;
    }

    function setUpdateValuesForConnectionCart() {
      let baseUnit = authContext?.accountData?.installedBaseUnits.find((b) => {
        return (
          b?.id ===
          cartContext?.lineItems?.find((lineItem) =>
            lineItem?.sku.startsWith("contract-")
          )?.installedBaseUnitId
        );
      });
        if (baseUnit) {
        setCheckboxValues(baseUnit?.isYoungerThan5Years ?? true, 'baseUnitAge');
        setCheckboxValues(baseUnit?.internetConnected ?? true, 'internetConnected');
        setCheckboxValues(baseUnit?.internetReady ?? null, 'internetReady');
      }
    }

    function isBronzeContractInCart(){
        return cartContext.lineItems?.findIndex(lineItem => lineItem.sku === 'contract-heatpump-bronze') >= 0;
    }

    function removeInstallationFeeLineItemFromCartContext(){
        let feeLineItemIndex = cartContext.lineItems?.findIndex(lineItem => lineItem.sku === 'internet-connection-service-package')
        if (feeLineItemIndex >= 0) {
            let lineItems = cartContext.lineItems;
            lineItems.splice(feeLineItemIndex, 1);
            setCartContext({
                ...cartContext,
                lineItems: [
                    ...lineItems
                ]
            });
        }
    }

    async function handleNextStepButtonClickWithoutFee(event){
        handleNextStepButtonClick(event, true)
    }

    async function handleNextStepButtonClick(event, withoutFee) {
        event.preventDefault();
        setIsLoading(true);
        if (
          countryCode === "en_IE" &&
          isBronzeContractInCart() &&
          internetConnected === false &&
          internetReady === true
        ) {
          withoutFee = true;
        }
        
        if (internetConnected) {
            removeInstallationFeeLineItemFromCartContext();
            navigation.goNext();
            return;
        }

        if (internetReady) {
            if(withoutFee){
                session.updateValuesForConnection('addConnectionService', false, "Add Connection Service: ", currentStep);
                removeInstallationFeeLineItemFromCartContext();
            } else {
                session.updateValuesForConnection('addConnectionService', true, "Add Connection Service: ", currentStep);
                setCartContext({
                    ...cartContext,
                    lineItems: [
                        ...cartContext.lineItems,
                        installationFeeContext
                    ]
                });
            }
            navigation.goNext();
            return;
        }

        if (!internetReady) {
            if (!wasSelectedPlanChanged && !isBronzeContractInCart()) {
                try {

                    const changeCartContractResponse = await changeCartContract('contract-heatpump-bronze', false,
                        getSessionStorageItem(ANONYMOUS_ID_SESSION_STORAGE_NAME) || '', authContext?.authToken)
                        .catch(e => {
                            ErrorResponseHandling(e, authContext, setAuthContext, history);
                        });

                    setProductContext(prevState => {
                        return {
                            ...prevState,
                            selectedPlan: changeCartContractResponse.data?.lineItems.find(x => x.sku).sku,
                            selectedVariant: null
                        }
                    })
                    setCartContext(changeCartContractResponse.data);
                    setSessionStorageItem(ANONYMOUS_ID_SESSION_STORAGE_NAME, changeCartContractResponse.data.anonymousId);
                    setSessionStorageItem(CART_ID_SESSION_STORAGE_NAME, changeCartContractResponse.data.id);
                    setWasSelectedPlanChanged(true);
                    setIsLoading(false);
                } catch (error) {
                    console.error(error);
                    navigation.setStep("error");
                }
            } else {
                navigation.goNext();
            }
        }
    }

    const getNextStepButtonText = () => {
        if (
          countryCode === "en_IE" &&
          isBronzeContractInCart() &&
          internetConnected === false &&
          internetReady === true
        ) {
          return currentStepValues.continue_btn;
        }

        if (internetConnected) {
            return currentStepValues.next_btn;
        }

        if (internetReady) {
            return installationFeeContext?.totalGross?.amount > 0 ? installationItems.add_installation_btn + " " + formatMoneyByCountryCode(installationFeeContext?.totalGross?.amount, countryCode) : installationItems.add_installation_btn;
        }

        if (internetReady === false) {
            if (wasSelectedPlanChanged || isBronzeContractInCart()) {
                return changePlanItems.change_plan_btn_sameid;
            } else {
                return changePlanItems.change_plan_btn;
            }
        }

        return currentStepValues.next_btn;
    }

    const renderCheckboxes = (item, i ) => {
        if(!item.inputs.checkboxes || item.name === "baseUnitAge"){
            return <></>;
        }

        return <>
            <FormFieldset key={i} className={i === 1 && internetConnected ? "d-none" : ""}>
                <FormHeadline headline={item.label}/>
                <FormRow>
                    <SimpleCheckbox
                        checkboxes={item.inputs.checkboxes}
                        label={item.name}
                        setCheckboxValues={setCheckboxValues}
                        startValue={item.startValue}
                        hint={item.hint}
                        images={item.images}
                    />
                </FormRow>
                {item.images && item.images[0].type === "image" &&
                <img className="simple_checkbox_image" alt="" src={item.images[0].path}/>}
                {item.images && item.images.length > 1 && item.images[1].type === "image" &&
                <img className="simple_checkbox_image" alt="" src={item.images[1].path}/>}
            </FormFieldset>
        </>
    }

    const renderInput = (item, i) => {
        if(!!item.inputs.checkboxes){

            if (item.name === "baseUnitAge") {
                return <FormFieldset key={i} className={i === 1 && internetConnected ? "d-none" : ""}>
                    <FormHeadline headline={item.label}/>
                    <FormRow>
                        <Checkbox
                            checkboxes={item.inputs.checkboxes}
                            label={item.name}
                            setCheckboxValues={setCheckboxValues}
                            startValue={item.startValue}
                            hint={item.hint}
                        />
                    </FormRow>
                </FormFieldset>
            }

            return <></>;
        }

        return <FormItem
                key={i}
                item={item.inputs[0][0]}
                saveValueByStep={saveValues}
                updateValuesByKey={updateValues}
                currentStepValues={currentStepValues || {}}
            />
    }

    const saveValues = (event, isInvalid) => {
        session.setValueAndValidity(event, isInvalid, currentStep);
        // props.session.updateValuesByStep(event, props.currentStep);
    }

    function updateValues(key, value, label) {
        // console.log('updateValues', ...arguments);
    }

    function areInputsValid() {
        if (!currentSessionValues) {
            return false;
        }
        return !currentSessionValues.some(sessionValue => sessionValue.invalid);
    }


    // translations
    const installationItems = currentStepValues.add_installation_items;
    const changePlanItems = currentStepValues.change_plan_items;

    function getAddFeeText() {
        return isBronzeContractInCart() ? installationItems.add_installation_text_comfort.replace("##price##", formatMoneyByCountryCode(installationFeeContext?.price?.amount, countryCode)) :  installationItems.add_installation_text.replace("##price##", formatMoneyByCountryCode(installationFeeContext?.price?.amount, countryCode));
    }

    return (
        <div className="registration_step_content">
            {
                currentStepValues.inputList.map((item, i) =>
                    renderInput(item, i)
                )
            }
            <FormText>{currentStepValues.text}</FormText>

            {
                currentStepValues.inputList.map((item, i) =>
                    renderCheckboxes(item, i)
                )
            }
            {!internetConnected && internetReady &&
            <FormInfoBox>
                <FormInfoBoxHeadline headline={installationItems.add_installation_label}/>
                <p dangerouslySetInnerHTML={{__html: getAddFeeText() }}/>
            </FormInfoBox>
            }
            {!internetConnected && internetReady === false && !isBronzeContractInCart() &&
            <FormInfoBox>
                <FormInfoBoxHeadline headline={changePlanItems.change_plan_label}/>
                <p dangerouslySetInnerHTML={{__html: changePlanItems.change_plan_text}}/>
            </FormInfoBox>
            }

            <StepButtons
                goBackToProtectionPlan={true}
                backButtonHref={currentStepValues.href}
                backButtonLabel={currentStepValues.href_label}
                nextButtonEnabled={(internetConnected || internetReady !== null) && !isLoading && areInputsValid()}
                showDisabled={areInputsValid()}
                nextButtonOnClick={handleNextStepButtonClick}
                nextButtonText={getNextStepButtonText()}
                isLoading={isLoading}
            />

            {
                !internetConnected && internetReady && isBronzeContractInCart() && countryCode !== "en_IE" &&
                <FormText> <StepButtons
                    goBackToProtectionPlan={false}
                    backButtonHref={currentStepValues.href}
                    backButtonLabel={currentStepValues.href_label}
                    nextButtonEnabled={(internetConnected || internetReady !== null) && !isLoading && areInputsValid()}
                    nextButtonOnClick={handleNextStepButtonClickWithoutFee}
                    nextButtonText={currentStepValues.continue_btn}
                    isLoading={isLoading}
                    btnAlignment="justify-content-end"
                    additionalClassNames="fixedwidth"
                />
                </FormText>
            }
        </div>
    )
}

export default ConnectionCheckStep;
