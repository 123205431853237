module.exports = {
    headline: 'Hold dig opdateret',
    icons: [
        {
            icon: "facebook",
            link: "https://www.facebook.com/PanasonicHeatingCoolingEU/"
        },
        {
            icon: "youtube",
            link: "https://www.youtube.com/c/PanasonicHeatingCoolingSolutionsEurope"
        },
        {
            icon: "linkedin",
            link: "https://www.linkedin.com/company/panasonic-heating-and-cooling-solutions-europe/"
        },
    ],
    links: [
        {
            href: "/da_DK/home/terms_and_conditions",
            label: "Korruptionsbekæmpelse"
        },
        {
            href: "/da_DK/home/cookies/",
            target: "_blank",
            label: "Cookiepolitik"
        },
        {
            href: "/da_DK/home/privacy_policy",
            label: "Fortrolighedspolitik"
        },
        {
            href: "/da_DK/home/terms_and_conditions",
            label: "Vilkår og betingelser"
        },
    ],
    copyright: "Copyright 2021 Panasonic Marketing Europe GmbH, alle rettigheder forbeholdes",
};
