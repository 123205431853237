// TODO: rename the keys to camelCase
module.exports = {
    headline: "Checkout",
    summary: {
        confirmation: {
            headline: "Konfirmation",
            subheadline: "Vælg pakke"
        },
    },
    steps: [
        {
            label: "Tjek forbindelse",
            href: "/da_DK/home/service_package",
            href_label: "Tilbage til servicepakker",
            next_btn: "Næste",
            continue_btn: "Fortsæt uden installationsservice",
            add_installation_items: {
                add_installation_btn: "Tilføj installationsservice til ",
                add_installation_label: "Vigtig information:",
                add_installation_text: 'For få det fulde udbytte af vores servicepakker er det nødvendigt, at din enhed har forbindelse til internettet. <br><br>For at sikre at din enhed er forbundet til internettet anbefaler vi, at du bestiller vores installationsservicepakke. Denne service betales med et engangsbeløb på ##price##<br><br>Vi tilføjer automatisk installationsservicepakke til din købsproces. Vi ringer til dig og arrangerer et tidspunkt for installationen.',
                add_installation_text_comfort: 'For få det fulde udbytte af vores servicepakker er det nødvendigt, at din enhed har forbindelse til internettet. <br><br>For at sikre at din enhed er forbundet til internettet anbefaler vi, at du bestiller vores installationsservicepakke. Denne service betales med et engangsbeløb på ##price##<br><br>Vi tilføjer automatisk installationsservicepakke til din købsproces. Vi ringer til dig og arrangerer et tidspunkt for installationen.',
            },
            change_plan_items: {
                change_plan_changeid: 2,
                change_plan_btn_sameid: "Næste",
                change_plan_btn: "Skift mit valg til Basic",
                change_plan_link: "",
                change_plan_label: "Vigtig information:",
                change_plan_text: 'Eftersom din enhed ikke er forbundet til internettet, kan vi på nuværende tidspunkt ikke tilbyde dig alle vores servicepakker. For at give dig den bedst mulige service ændrer vi din valgte servicepakke til Aquarea Service+ Basic.',
            },
            text: "For at give dig den bedste service vil vi sikre os, at dine enheder har forbindelse til internettet.",
            inputList: [
                {
                    label: "Er din enhed tilsluttet til internettet?",
                    width: "col",
                    name: "internetConnection",
                    startValue: true,
                    hint: {
                        label: "ikke sikker?",
                        text: "test",
                        img: "https://picsum.photos/400/200",
                        img_alt: ""
                    },
                    images: [
                        {path: "/image/checkout/CloudAdapter.jpg", type: "image"}
                    ],
                    inputs: {
                        checkboxes: [
                            {
                                label: "Ja",
                                key: "internetConnected",
                                defaultValue: true,
                                value: true,
                            },
                            {
                                label: "Nej",
                                key: "internetConnected",
                                defaultValue: false,
                                value: false,
                            }
                        ]
                    }
                },
                {
                    label: "Hvilken type fjernbetjening har du?",
                    width: "col",
                    name: "unitForInternet",
                    startValue: true,
                    hint: {
                        label: "ikke sikker?",
                        text: "",
                        img: "",
                        img_alt: ""
                    },
                    images: [
                        {path: "/image/checkout/NewController.jpg", type: "label"},


                        {path: "/image/checkout/OldController.jpg", type: "label"},
                    ],
                    inputs: {
                        checkboxes: [
                            {
                                label: "Ja",
                                key: "internetReady",
                                defaultValue: false,
                                value: true
                            },
                            {
                                label: "Nej",
                                key: "internetReady",
                                defaultValue: false,
                                value: false
                            }
                        ]
                    }
                },
                {
                    label: "Er din varmepumpeinstallation yngre end 5 år?",
                    width: "col",
                    name: "baseUnitAge",
                    startValue: true,
                    inputs: {
                        checkboxes: [
                            {
                                label: "Ja",
                                key: "baseUnitAge",
                                defaultValue: true,
                                value: true,
                            },
                            {
                                label: "Nej",
                                key: "baseUnitAge",
                                defaultValue: false,
                                value: false,
                            }
                        ]
                    }
                },
            ],
        },
        {
            label: "Kontoinformation",
            href: "/da_DK/home/service_package",
            href_label: "Tilbage",
            next_btn: "Næste",
            inputList: [
                {
                    headline: "Opret ny konto",
                    login: "LOG IND",
                    login_path: "",
                    login_label: "Eksisterende bruger? ",
                    inputs: [
                        [{
                            label: "E-mail",
                            name: "email",
                            placeholder: "",
                            type: "email",
                            validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH", "EMAIL"],
                            constraints: {minLength: 3, maxLength: 80},
                            width: "col-12 col-lg-6",
                            checkId: "email",
                            validation: "matching_email"
                        },
                            {
                                label: "Gentag E-mail",
                                name: "repeat-email",
                                placeholder: "Email",
                                type: "email",
                                validators: ["REQUIRED", "COMPARE"],
                                validationMessages: {
                                    COMPARE: "E-mailen skal være den samme",
                                },
                                constraints: {compareControl: "email"},
                                width: "col-12 col-lg-6",
                                checkId: "email",
                            }],
                        [{
                            label: 'Adgangskode <span>Min. 8 tegn, tal og bogstaver</span>',
                            name: "password",
                            placeholder: "",
                            type: "password",
                            validators: ["REQUIRED", "MIN_LENGTH","PASSWORD"],
                            constraints: {minLength: 8},
                            width: "col-12 col-lg-6",
                            checkId: "password",
                        },
                            {
                                label: "Gentag adgangskode",
                                name: "repeat-password",
                                placeholder: "Password",
                                type: "password",
                                validators: ["REQUIRED", "COMPARE"],
                                validationMessages: {
                                    COMPARE: "Adgangskoden skal være den samme",
                                },
                                constraints: {compareControl: "password", minLength: 8},
                                width: "col-12 col-lg-6",
                                checkId: "password",
                            }]
                    ]
                },
                {
                    headline: "Kontoadresse",
                    inputs: [
                        [{
                            label: "Fornavn",
                            name: "firstName",
                            placeholder: "",
                            type: "text",
                            validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
                            constraints: {minLength: 2, maxLength: 19},
                            width: "col-12 col-lg-6",
                            validation: "required"
                        },
                            {
                                label: "Efternavn",
                                name: "lastName",
                                placeholder: "",
                                validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
                                constraints: {minLength: 2, maxLength: 19},
                                type: "text",
                                width: "col-12 col-lg-6",
                                validation: "required"
                            }],
                        [{
                            label: "Adresse",
                            name: "street",
                            placeholder: "",
                            type: "text",
                            validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
                            constraints: {minLength: 1, maxLength: 30},
                            width: "col"
                        }],
                        [{
                            label: "Postnummer",
                            name: "zipCode",
                            placeholder: "",
                            validators: ["REQUIRED", "EXACTLY_LENGTH"],
                            constraints: {exactlyLength: 4},
                            type: "text",
                            width: "col-6"
                        },
                            {
                                label: "By",
                                name: "city",
                                placeholder: "",
                                validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
                                constraints: {minLength: 1, maxLength: 35},
                                type: "text",
                                width: "col-6"
                            },
                            {
                                label: "LAND <img src='/image/menu/locales/denmark.jpg' style='width: 1.5em'/> <br> <span> Denne servicepakke og disse betingelser gælder for enheder, der er installeret i det pågældende land. Hvis din varmepumpe er installeret i et andet land, skal du vælge det land øverst til højre på siden, der viser de lande, hvor Aquarea Service+ er tilgængelig.</span>",
                                name: "country",
                                type: "country",
                                width: "col-12"
                            }],
                        [{
                            label: "Telefonnummer",
                            name: "phoneNumber",
                            key: "phoneNumber",
                            placeholder: "",
                            validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
                            constraints: {minLength: 9, maxLength: 17},
                            type: "phone",
                            width: "col",
                            offset:3
                        }]
                    ]
                },
            ],
        },
        {
            label: "Betalingsoplysninger",
            href: "/da_DK/home/service_package",
            href_label: "Tilbage",
            subheadline: "Model for kontraktbetaling",
            per_month:"pr. måned",
            pay: "betale",
            monthly:"om måneden",
            quarterly:"pr. kvartal",
            annually:"årligt",
            discount: {
                name: "Rabat",
                placeholder: "indsæt rabatkode",
                button: "anvend",
                remove: "fjern",
                invalidHeadline: "Den indtastede kode er ugyldig",
                invalidHint: "Kontroller venligst dine input",
                success: "Din rabatkode er accepteret og vil automatisk blive anvendt på dit køb. Bemærk venligst, at der ikke vil kunne trækkes fra det samlede betalingsbeløb før efter check out, hvor du kan se denne rabat i din betalingsplan.",
                errors: {
                    tooLong: "Den indtastede rabatkode er for lang.",
                    invalidInput: "Ingen rabat indtastet"
                }
            },
            text: "Vælg venligst din betalingsmetode. Betalingen vil blive gennemført efter checkout.",
            disclaimer: "Når du køber servicepakke \"Aquarea + Premium\", får du \"Aquarea + Smart\", indtil Panasonics tekniker kontrollerer installationen og godkender, at vigtige tekniske krav er opfyldt. Hvis du ikke opfylder disse tekniske krav, vil du forblive og nyde fordelene ved \"Aquarea + Smart\"-pakken. Indtil din installation er godkendt til \"Aquarea + Premium\", bliver du opkrævet for \"Aquarea + Smart\"-pris.",
            credit_secure: "Bemærk venligst, at vores platform kun accepterer kreditkort, der er 3D Secure-aktiveret.\n" +
                "3D Secure er oftest et ekstra trin i betalingsprocessens validering, hvor kortindehaveren skal indtaste en kode, der\n" +
                "er blevet sendt til mobiltelefonen som en del af checkout-processen. \n" +
                "For at tilmelde dit kreditkort til dette skal du kontakte din bank eller kreditkortudsteder direkte\n" +
                "eller se deres websted for yderligere instruktioner. Alternativt kan du betale med PayPal.\n",
            visa_secure: "/image/icons/checkout/visaSecure.png",
            visa_secure_alt: "visa secure",
            mastercard_id_check: "/image/icons/checkout/mastercardIdCheckBlack.png",
            mastercard_id_check_alt: "mastercard id check",
            agreement: "Jeg bekræfter, at jeg har læst og indvilliger i <a href=\"/da_DK/home/terms_and_conditions\" target=\"_blank\">vilkårene og betingelserne for Aquarea Service+</a>, og jeg er bekendt med <a href=\"/da_DK/home/privacy_policy\" target=\"_blank\">privatlivspolitikken for Aquarea Service+.</a>",
            policy: "Jeg anmoder og accepterer udtrykkeligt, at Panasonic påbegynder den aftalte service inden udløbet af fortrydelsesfristen.\n Jeg vil være forpligtet til at betale for alle tjenester, herunder reparations- og vedligeholdelsestjenester, der er udført, før jeg udnytter 14-dages fortrydelsesretten.",
            campaign: "Jeg accepterer at modtage en separat mail fra Treedom med en kode til at få igangsat platning af mit træ.",
            next_btn: "Næste",
            inputList: [
                {
                    headline: "Enter payment details",
                    inputs: [
                        {
                            label: "Payment method",
                            name: "payment_opt",
                            type: "payment_opt",
                            payments: [
                                {
                                    id: "0",
                                    label: "Payment method",
                                    key: "paymentOpt",
                                    value: "credit",
                                    image: "/image/icons/checkout/credit.png",
                                    isChecked: false
                                },
                                {
                                    id: "1",
                                    label: "Payment method",
                                    key: "paymentOpt",
                                    value: "paypal",
                                    image: "/image/icons/checkout/paypal.png",
                                    isChecked: false
                                },
                            ],
                            width: "col"
                        },
                        {
                            label: "CARD NUMBER",
                            name: "card_number",
                            placeholder: "",
                            type: "text",
                            width: "col"
                        },
                        {
                            label: "EXPIRATION DATE",
                            name: "expiration_date",
                            placeholder: "Expiration date",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            label: "CVV",
                            name: "cvv",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            labels: [
                                {
                                    id: "datarights",
                                    label: "Bekræft venligst at du har læst og accepterer betingelserne i Aquarea Service+ kundeaftalen",
                                    name: "datarights",
                                    value: "",
                                    width: "col"
                                },
                            ],
                            type: "radio",
                            width: "col",
                        }
                    ]
                },
            ],
        },
        {
            label: "Konfirmation",
            href: "/protection_plans",
            href_label: "Tilbage",
            next_btn: "Næste",
            inputList: [
                {
                    headline: "Opret ny konto",
                    inputs: [
                        {
                            label: "EMAIL",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            label: "PASSWORD",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        }
                    ]
                },
                {
                    headline: "Kontoadresse",
                    inputs: [
                        {
                            label: "CUSTOMER TYPE",
                            name: "customer",
                            placeholder: "Private",
                            type: "select",
                            width: "col col-lg-6",
                            options: [
                                "Private",
                                "test1",
                                "test2",
                                "test3",
                                "test4",
                                "test5",
                                "test6"
                            ]
                        },
                        {
                            label: "FORNAVN",
                            name: "first_name",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            label: "EFTERNAVN",
                            name: "last_name",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            label: "ADRESSE",
                            name: "street",
                            placeholder: "",
                            type: "text",
                            width: "col"
                        },
                        {
                            label: "POSTNUMMER",
                            name: "zipCode",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            label: "BY",
                            name: "city",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            label: "TELEFONNUMMER",
                            name: "phoneNumber",
                            placeholder: "",
                            type: "text",
                            width: "col"
                        }
                    ]
                }
            ],
        }
    ]
}

