module.exports = {
    logo: {
        icon: "/image/logo/panasonic.svg",
        href: "/de_DE/home",
        alt: "Panasonic",
        title: "Panasonic",
        name: "Panasonic",
        aria_label: "Panasonic"
    },
    logout: "Abmelden",
    greeting: "Hallo, {{name}}",
    links: [
        {
            text: "SERVICE-PAKETE",
            href: "/de_DE/home/service_package",
            linkType: "",
            dropdown: [
                {
                    text: "PAKETE ÜBERSICHT",
                    href: "plans_overview",
                    linkType: ""
                },
                {
                    text: "PRODUKTFINDER",
                    href: "product_finder",
                    linkType: ""
                }
            ]
        },
        {
            text: "SERVICE-KONTAKT",
            href: "https://www.aircon.panasonic.eu/DE_de/contact/",
            linkType: "blank",
        },
    ],
    language: {
        flag: "/image/menu/locales/germany.jpg",
        title: "/image/menu/heating_cooling.png",
        alt: "german_flag"
    },
    languages: [
        {
            flag: "/image/menu/locales/denmark.jpg",
            name: "Danmark",
            link: "da_DK"
        },
        {
            flag: "/image/menu/locales/germany.jpg",
            name: "Deutschland",
            link: "de_DE"
        },
        {
            flag: "/image/menu/locales/spain.jpg",
            name: "España",
            link: "es_ES"
        },
        {
            flag: "/image/menu/locales/ireland.jpg",
            name: "Ireland",
            link: "en_IE"
        },
        {
            flag: "/image/menu/locales/italy.jpg",
            name: "Italia",
            link: "it_IT"
        },
        {
            flag: "/image/menu/locales/austria.jpg",
            name: "Österreich",
            link: "de_AT"
        },
        {
            flag: "/image/menu/locales/sweden.jpg",
            name: "Sverige",
            link: "sv_SE"
        }
    ],
    btn: {
        text: "MEIN KONTO",
        href: "/de_DE/home/my_account",
        linkType: "btn",
    },
    cart: {
        icon: "",
        href: "/de_DE/home/cart",
        linkType: "cart",
    },
    localizationChangeInfo: "Durch Ändern des Länderbereichs wird Ihr Bestellvorgang abgebrochen und Sie werden abgemeldet.",
    okBtn: "Land ändern",
    discardBtn: "Land nicht ändern"
};
