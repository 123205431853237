module.exports = {
    title: "Kündigung",
    headline: "Es tut uns sehr leid, dass Sie Ihr Abonnement kündigen möchten.",
    btnReturnToProfile: "Zurück zu meinem Konto",
    cancelSectionTitle: "Abonnement kündigen",
    cancelText: "Möchten Sie das Abonnementpaket Aquarea Service+ wirklich kündigen?",
    btnCancelSubscription: "Ja, bitte mein Abonnement kündigen",
    downgradeSectionTitle: "Downgrade Ihres Abonnements",
    downgradeText: "Folgende Downgrade-Optionen stehen zur Verfügung:",
    btnDowngradeOrder: "Downgrade meines Abonnements",
    downgradeOptions: {
        silver: "Downgrade auf Aquarea Service+ SMART-Paket",
        bronze: "Downgrade auf Aquarea Service+ COMFORT-Paket"
    }
}
