module.exports = {
    headline: "Leistungsspektrum",
    desc_label: "Beschreibung",
    terms_label: "Allgemeine Geschäftsbedingungen",
    annually: 'pro Jahr',
    quarterly: 'pro Quartal',
    monthly: 'pro Monat',
    list: [
        {
            headline: '/image/plan_cards/aquarea_service+_exclusive.svg',
            choiceList_image: "/image/plan_cards/aquarea_service+_exclusive.svg",
            subheadline: 'pro Monat',
            sku: "contract-heatpump-gold",
            color: "yellow",
            image: "/image/plan_cards/1.jpg",
            imageiconlist: "/image/test/screen9.png",
            text: "Mit Ihrem Aquarea Service+ Premium-Abonnement haben Sie eine Sorge weniger. Ihre Wärmepumpe wird permanent fernüberwacht und wenn ein Fehler auftritt, erfahren wir als Erste davon. Wir kontaktieren Sie umgehend und helfen Ihnen, das Problem aus der Ferne zu lösen. Wenn dies nicht möglich ist, vereinbaren wir innerhalb von 24 Stunden einen Servicetermin, auch am Wochenende, und übernehmen dabei die Arbeits- und Ersatzteilkosten!",
            disclaimer: "Beim Kauf eines Servicepakets „Aquarea+ Premium“ erhalten Sie „Aquarea+ Smart“, bis ein Panasonic-Techniker die Installation überprüft und die Erfüllung der wesentlichen technischen Voraussetzungen bestätigt. Sollten diese technischen Voraussetzungen nicht erfüllt sein, bleiben Sie „Aquarea+ Smart“-Kunde und können die Leistungen dieses Pakets nutzen. Bis zur Freigabe Ihrer Installation für „Aquarea+ Premium“ wird Ihnen der „Aquarea+ Smart“-Preis berechnet.",
            info: "Sie müssen nichts weiter tun, als den Komfort Ihres Zuhauses zu genießen.",
            good_to_know_title: "Gut zu wissen",
            good_to_know_list: [
                {
                    label: "Ihr Vertrag ist bis zum Jahresende kündbar"
                },
            ],
            link: "Vollständige Bedingungen herunterladen",
            linkHref: "/de_AT/home/terms_and_conditions",
            btn_goTo: {
                href: "",
                label: "Hier geht es zur Vergleichstabelle"
            },
            btn_book: {
                href: "",
                label: "Premium Paket kaufen"
            },
            iconList: [
                {
                    icon: "/image/icons/services/maintenance.svg",
                    alt: "",
                    label: "Jährlicher Wartungsbesuch",
                },
                {
                    icon: "/image/icons/services/remotely_diagnose.svg",
                    alt: "",
                    label: "24/7 Reaktionszeit",
                },
                {
                    icon: "/image/icons/services/maintenance_report.svg",
                    alt: "",
                    label: "Intelligente Aquarea-Berichte",
                },
                {
                    icon: "/image/icons/services/permanent_monitoring.svg",
                    alt: "",
                    label: "IoT-Überwachung rund um die Uhr",
                },
                {
                    icon: "/image/icons/services/service_technicians.svg",
                    alt: "",
                    label: "Ersatzteile und Arbeitsaufwand inklusive",
                }
            ]
        },
        {
            headline: '/image/plan_cards/aquarea_service+_advanced.svg',
            choiceList_image: "/image/plan_cards/aquarea_service+_advanced.svg",
            subheadline: 'pro Monat',
            color: "green",
            sku: "contract-heatpump-silver",
            image: "/image/plan_cards/3.jpg",
            imageiconlist: "/image/test/screen8.png",
            text: "Mit Ihrem Aquarea Service+ Smart-Abo haben Sie eine Sorge weniger. Ihre Wärmepumpe wird permanent fernüberwacht und wenn ein Fehler auftritt, erfahren wir es als Erstes. Wir nehmen umgehend Kontakt mit Ihnen auf und helfen, das Problem aus der Ferne zu lösen oder, falls dies nicht möglich ist, vereinbaren wir innerhalb von 24 Stunden, auch am Wochenende, einen Servicetermin.",
            info: "Sie müssen nichts weiter tun, als den Komfort Ihres Zuhauses zu genießen.",
            good_to_know_title: "Gut zu wissen",
            good_to_know_list: [
                {
                    label: "Ihr Vertrag ist zum Jahresende kündbar"
                },
            ],
            link: "Vollständige Bedingungen herunterladen",
            linkHref: "/de_AT/home/terms_and_conditions",
            btn_goTo: {
                href: "",
                label: "Hier geht es zur Vergleichstabelle"
            },
            btn_book: {
                href: "",
                label: "Smart Paket kaufen"
            },
            iconList: [
                {
                    icon: "/image/icons/services/maintenance.svg",
                    alt: "",
                    label: "Jährlicher Wartungsbesuch",
                },
                {
                    icon: "/image/icons/services/247_call.svg",
                    alt: "",
                    label: "24/7 Priority Support Hotline",
                },
                {
                    icon: "/image/icons/services/permanent_monitoring.svg",
                    alt: "",
                    label: "IoT-Überwachung rund um die Uhr",
                },
                {
                    icon: "/image/icons/services/repair_discount.svg",
                    alt: "",
                    label: "20 % Ersatzteilrabatt",
                }
            ]
        },
        {
            headline: '/image/plan_cards/aquarea_service+_comfort.svg',
            choiceList_image: "/image/plan_cards/aquarea_service+_comfort.svg",
            subheadline: 'pro Monat',
            color: "brown",
            sku: "contract-heatpump-bronze",
            image: "/image/plan_cards/2.jpg",
            imageiconlist: "/image/test/screen7.png",
            text: "Mit Ihrem Aquarea Service+ Comfort-Abonnement erhalten Sie ein komfortables Paket, das einen jährlichen Wartungsbesuch Ihrer Wärmepumpe sowie den Zugang zu einer Priority-Hotline beinhaltet.",
            info: "Sie müssen nichts weiter tun, als den Komfort Ihres Zuhauses zu genießen.",
            good_to_know_title: "Gut zu wissen",
            good_to_know_list: [
                {
                    label: "Ihr Vertrag ist zum Jahresende kündbar"
                },
            ],
            link: "Vollständige Bedingungen herunterladen",
            linkHref: "/de_AT/home/terms_and_conditions",
            btn_goTo: {
                href: "",
                label: "Hier geht es zur Vergleichstabelle"
            },
            btn_book: {
                href: "",
                label: "Comfort Paket kaufen"
            },
            iconList: [
                {
                    icon: "/image/icons/services/maintenance.svg",
                    alt: "",
                    label: "Jährlicher Wartungsbesuch",
                },
                {
                    icon: "/image/icons/services/call.svg",
                    alt: "",
                    label: "Priority hotline",
                }
            ]
        },

    ],
    modal: {
        editContractTitle: "Vertrag bearbeiten",
        editContractBody: "Mit der von Ihnen gewählten Konfiguration ist nur der Vertrag Aquarea Service + Comfort anwendbar.",
        primaryButton: "Comfort Auswählen",
        secondaryButton: "Vertrag ändern",
    }
};
