import React, { useContext, useEffect, useState } from "react";
import i18n from "../../lib/i18n";
import {Link} from 'react-router-dom';
import { DetailContainer, DetailEntry, DetailHeadline, DetailList, OptionalValue } from "../layout/detailEntry";
import { exceptedPaymentMethods } from "../stepForm/steps/types/paymentMethods";
import {InstallationFeeContext} from "../../lib/contexts/installationFeeContext";
import {ProductContext} from "../../lib/contexts/productContext";
import { getPriceForSku, getPaymentPeriodForSku } from "../../lib/helper/productHelper";
import {COUNTRY_CODE_NAME, getSessionStorageItem} from "../../lib/sessionStorage";
import { formatMoneyByCountryCode } from "../../lib/helper/moneyHelper";

const t = i18n.namespace("SummaryList");
const p = i18n.namespace("PlanCard");
// const planCardList = p("list");

function CheckoutSummary({ summaryData, paymentMethodId, selectedPlanSku}) {
    const [usedPaymentMethod, setUsedPaymentMethod] = useState(null);
    const [selectedPlanIndex, setSelectedPlanIndex] = useState(null);
	const { installationFeeContext } = useContext(InstallationFeeContext);
    const { productContext } = useContext(ProductContext);

    const countryCode = getSessionStorageItem(COUNTRY_CODE_NAME) || 'da_DK';

    useEffect(() => {
        if (paymentMethodId) {
            setUsedPaymentMethod(exceptedPaymentMethods(countryCode).find(paymentMethod => paymentMethod.id === paymentMethodId));
        }
    }, [paymentMethodId, countryCode]);

    useEffect(() => {
        setSelectedPlanIndex(mapSkuToIndex(selectedPlanSku));
    }, [selectedPlanSku]);

    const mapSkuToIndex = (sku) => {
        if (sku.startsWith('contract-heatpump-gold')) {
            return 0;
        }
        if (sku.startsWith('contract-heatpump-silver')) {
            return 1;
        }
        if (sku.startsWith('contract-heatpump-bronze')) {
            return 2;
        }

        return null;
    }

    return (
        <div>
            {selectedPlanIndex !== null &&
                <div className={"registration_confirmation_step_plan registration_confirmation_step_plan--" + p("list")[selectedPlanIndex].color}>
                    <img src={p("list")[selectedPlanIndex].headline} alt="" />
                </div>
            }
            <DetailContainer>
                <DetailList className="detail_list--condenced">
                    <DetailEntry label={t(getPaymentPeriodForSku(selectedPlanSku, productContext)) + ":"}>
                    {getPriceForSku(selectedPlanSku, productContext, countryCode)}
                    </DetailEntry>
                    <OptionalValue condition={summaryData.connectionCheck.feeRequired} emptyValue="">
                        <DetailEntry label={t("details.connectionFeePrice") + ":"}>
                            {formatMoneyByCountryCode(installationFeeContext?.totalGross?.amount, countryCode)}
                        </DetailEntry>
                    </OptionalValue>
                </DetailList>
                <DetailList className="detail_list--condenced">
                    <DetailHeadline headline={t("connectionCheck")} />

                    <DetailEntry label={t("details.internetConnected") + ":"}>
                        {summaryData.connectionCheck.internetConnected ? t("yes") : t("no")}
                    </DetailEntry>
                    <DetailEntry label={t("details.internetReady") + ":"}>
                        {summaryData.connectionCheck.internetReady ? t("yes") : t("no")}
                    </DetailEntry>
                </DetailList>

                <DetailList className="detail_list--condenced">
                    <DetailHeadline headline={t("installedBase")} />

                    <DetailEntry label={t("details.modelName") + ":"}>
                        {summaryData.installedBase.model}
                    </DetailEntry>
                    <DetailEntry label={t("details.serialNumber") + ":"}>
                        {summaryData.installedBase.serialNumber}
                    </DetailEntry>
                    <DetailEntry label={t("details.gatewayId") + ":"}>
                        <OptionalValue condition={summaryData.installedBase.gatewayId}>
                            {summaryData.installedBase.gatewayId}
                        </OptionalValue>
                    </DetailEntry>
                    <DetailEntry label={t("details.installationDate") + ":"}>
                        {summaryData.installedBase.installationDate}
                    </DetailEntry>
                </DetailList>

                <DetailList className="detail_list--condenced">
                    <DetailHeadline headline={t("accountInformation")}>
                        <Link className="detail_headline_action_btn" to={"/"+ countryCode +"/home/my_account/edit"}>
                            {t("editBtn")}
                        </Link>
                    </DetailHeadline>

                    <DetailEntry label={t("details.name") + ":"}>
                        {summaryData.account.firstName + " "
                            + summaryData.account.lastName}
                    </DetailEntry>
                    <DetailEntry label={t("details.email") + ":"}>
                        {summaryData.account.email}
                    </DetailEntry>
                    <DetailEntry label={t("details.address") + ":"}>
                        {summaryData.address.street}
                    </DetailEntry>
                    <DetailEntry label={t("details.zipCode") + ":"}>
                        {summaryData.address.zipCode}
                    </DetailEntry>
                    <DetailEntry label={t("details.city") + ":"}>
                        {summaryData.address.city}
                    </DetailEntry>
                    <DetailEntry label={t("details.phone") + ":"}>
                        {summaryData.address.phoneNumber}
                    </DetailEntry>
                </DetailList>

                {usedPaymentMethod &&
                    <DetailList className="detail_list--condenced">
                        <DetailHeadline headline={t("paymentDetails")}>
                            <button className="detail_headline_action_btn" type="button">{t("editBtn")}</button>
                        </DetailHeadline>
                        <DetailEntry label={t("details.paymentMethod") + ":"}>
                            <img src={usedPaymentMethod.image} alt={usedPaymentMethod.label} /> {usedPaymentMethod.label}
                        </DetailEntry>
                    </DetailList>
                }
            </DetailContainer>
        </div>
    );
}

export default CheckoutSummary;