module.exports = {
    inputs: [
        {
            label: "Neues Passwort",
            id: "newPassword",
            name: "newPassword",
            placeholder: "Neues Passwort",
            type: "password"
        },
        {
            label: "Passwort bestätigen",
            id: "confirmPassword",
            name: "confirmPassword",
            password_placeholder: "Passwort bestätigen",
            type: "password"
        }
    ],
    success: "Passwort zurücksetzen angefordert, bitte schauen Sie in Ihren E-Mail-Posteingang!",
    subheadline: "Passwort zurücksetzen",
    headline: "Passwort zurücksetzen",
    resetBtn: "Passwort zurücksetzen"
}