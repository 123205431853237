module.exports = {
    headline: "WILLKOMMEN ZURÜCK",
    subheadline: "WILLKOMMEN ZURÜCK",
    inputs: [
        {
            label: "E-Mail",
            id: "email",
            name: "email",
            placeholder: "E-Mail",
            type: "email"
        },
        {
            label: "Passwort",
            id: "password",
            name: "password",
            password_placeholder: "Passwort",
            type: "password"
        }
    ],
    btn: "ANMELDEN",
    link: "PASSWORT VERGESSEN?",
    link_href: "/de_AT/home/password_forgot",
    newProtectionPlan: {
        label: "Benötigen Sie ein Servicepaket?",
        link: "Finden Sie ein Servicepaket?",
        link_href: "/de_AT/home/service_package",
    }

}