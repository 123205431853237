module.exports = {
    headline: "Registro Unidad de Bomba de Calor",
    date: {
        title: "¿Fecha de instalación de su aerotermia?",
        name: "fecha de instalación"
    },
    model: {
        title: "¿Qué modelo de unidad exterior tienes?",
        name: "NOMBRE DEL MODELO",
        img: {
            linkText: "¿Donde encontrarlo?",
            path: "/image/checkout/heatpump.png",
        },
        emptyOption: "-- Elija el modelo --",
        options: [
            "No lo sé.",
            "WH-MDC05H3E5",
            "WH-MDC05J3E5",
            "WH-MDC06G3E5",
            "WH-MDC07H3E5",
            "WH-MDC07J3E5",
            "WH-MDC07J3E5E",
            "WH-MDC09H3E5",
            "WH-MDC09J3E5",
            "WH-MDC09J3E5E",
            "WH-MDC12H6E5",
            "WH-MDC16H6E5",
            "WH-MHF09G3E5",
            "WH-MHF12G6E5",
            "WH-MHF12G6E5E",
            "WH-MHF12G9E8",
            "WH-MXC09H3E5",
            "WH-MXC09H3E8",
            "WH-MXC09J3E5",
            "WH-MXC09J3E5-1",
            "WH-MXC09J3E8",
            "WH-MXC12H6E5",
            "WH-MXC12H9E8",
            "WH-MXC12J6E5",
            "WH-MXC12J9E8",
            "WH-MXC16H9E8",
            "WH-MXC16J9E8",
            "WH-MXC16J9E8E",
            "WH-UD03HE5",
            "WH-UD03HE5-1",
            "WH-UD03JE5",
            "WH-UD05HE5-1",
            "WH-UD05JE5",
            "WH-UD07HE5-1",
            "WH-UD07JE5",
            "WH-UD07JE5E",
            "WH-UD09HE5-1",
            "WH-UD09HE8",
            "WH-UD09JE5",
            "WH-UD09JE5-1",
            "WH-UD12HE5",
            "WH-UD12HE5E",
            "WH-UD12HE8",
            "WH-UD16HE5",
            "WH-UD16HE5E",
            "WH-UD16HE8",
            "WH-UD16HE8E",
            "WH-UDZ03KE5",
            "WH-UDZ05KE5",
            "WH-UDZ07KE5",
            "WH-UDZ07KE5E",
            "WH-UDZ09KE5",
            "WH-UDZ09KE8",
            "WH-UDZ12KE5",
            "WH-UDZ12KE8",
            "WH-UH09FE5",
            "WH-UH09FE8",
            "WH-UH12FE5",
            "WH-UH12FE8",
            "WH-UQ16HE8",
            "WH-UX09HE5",
            "WH-UX09HE8",
            "WH-UX12HE5",
            "WH-UX12HE8",
            "WH-UX16HE8",
            "WH-UXZ09KE5",
            "WH-UXZ09KE8",
            "WH-UXZ12KE5",
            "WH-UXZ12KE8",
            "WH-UXZ16KE8",
            "WH-WDG05LE5",
            "WH-WDG07LE5",
            "WH-WDG09LE5",
            "WH-WXG09ME8",
            "WH-WXG12ME8",
            "WH-WXG16ME8",
            "WH-WXG20ME8",
            "WH-WXG25ME8",
            "WH-WXG30ME8"
        ]
    },
    serialNumber: {
        name: "NÚMERO DE SERIE",
        img: {
            linkText: "¿Donde encontrarlo?",
            path: "/image/checkout/heatpump.png",
        },
    },
    gatewayId: {
        title: "Si ya tiene el adaptador de red, introduzca el ID del dispositivo",
        name: "DEVICE ID (OPCIONAL)",
        img: {
            linkText: "¿Donde encontrarlo?",
            path: "/image/checkout/deviceID.png"
        }
    },
    shippingAddress: {
        title: "Dirección de instalación",
        street: {
            label: "Dirección",
            name: "street",
        },
        zipcode: {
            label: "Código postal",
            name: "zipcode",
        },
        city: {
            label: "Ciudad",
            name: "city",
        },
    },
    btn: {
        back: "VOLVER A MI CUENTA",
        next: "GUARDAR"
    },
    errors: {
        futureDate: "la fecha de instalación es futura",
        dateTooFarInPast: "la fecha de instalación es demasiado temprana",
        onlyDigits: "sólo se permiten dígitos",
        lengthIs11: "la longitud del identificador del dispositivo debe ser 11",
        zipCode: "Se requieren 5 números exactos",
        serialMin: "Mínimo 8 dígitos",
        serialMax: "Máximo 20 dígitos",
        requiredField: "Este campo es obligatorio",
        addressTooLong: "Máximo 30 caracteres",
        cityTooLong: "Máximo 35 caracteres"
    }
};
