module.exports = {
    yourProtectionPlan: "Ihr Leistungspaket",
    contract: "Vertrag",
    invoiceHistory: "Zahlungsverlauf",
    accountInformation: "Kontoinformationen",
    welcome: "Willkommen, ",
    noServicePackage: "Noch kein Servicepaket",
    editAccount: "Kontoinformationen bearbeiten",
    finishCheckout: "Unvollständiger Checkout",
    gotoCheckout: "Unvollständigen Checkout abschließen",
    finishUpgrade: "Bestell-Upgrade abschließen",
}
