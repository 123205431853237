module.exports = {
  failure: "Das Update wurde vom Zahlungsdienstsystem abgelehnt",
  cancel: "Das Update wurde abgebrochen",
  error:
    "Bei der Aktualisierung unseres Zahlungsdienstsystems ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
  success: "Ihr Update war erfolgreich",
  expired: "Die URL ist abgelaufen – Bitte versuchen Sie es erneut.",
  pending: "Antwort vom Zahlungsdienstsystem steht aus",
  link: "zurück zu meinem Konto",
  link_href: "/de_DE/home/my_account",
};
