module.exports = {
  headline: "Upgrade Choices",
  currentProtectionPlan: "Current Protection Plan",
  protectionPlan: "Protection Plan",
  validUntil: "contract valid till",
  costs: "costs",
  period: "payment period",
  monthly: "monthly",
  quarterly: "quarterly",
  annually: "annually",
  notUpgradableText: "You cannot upgrade from your current contract.",
  backButtonText: "Back To My Account",
};
