module.exports = {
    headline: "Registrer varmepumpeenhed",
    date: {
      title: "Installationsdato for din Varmepumpe",
      name: "installationsdato"
    },
    model: {
        title: "Hvilken model er installeret?",
        name: "MODELNAVN",
        img: {
            linkText: "HVOR SKAL MAN FINDE?",
            path: "/image/checkout/heatpump.png",
        },
        emptyOption: "-- vælge model --",
        options: [
            "Jeg ved det ikke.",
            "WH-MDC05H3E5",
            "WH-MDC05J3E5",
            "WH-MDC07H3E5",
            "WH-MDC07J3E5",
            "WH-MDC09H3E5",
            "WH-MDC09J3E5",
            "WH-MXC09G3E8",
            "WH-MXC09H3E5",
            "WH-MXC09H3E8",
            "WH-MXC09J3E8",
            "WH-MXC12H9E8",
            "WH-MXC12J9E8",
            "WH-MXC16H9E8",
            "WH-MXC16J9E8",
            "WH-UD03HE5",
            "WH-UD03HE5-1",
            "WH-UD03JE5",
            "WH-UD05HE5",
            "WH-UD05HE5-1",
            "WH-UD05JE5",
            "WH-UD07HE5-1",
            "WH-UD07JE5",
            "WH-UD09HE5-1",
            "WH-UD09HE8",
            "WH-UD09JE5-1",
            "WH-UD12HE8",
            "WH-UD16HE8",
            "WH-UDZ03KE5",
            "WH-UDZ05KE5",
            "WH-UDZ07KE5",
            "WH-UDZ09KE5",
            "WH-UDZ12KE8",
            "WH-UH12FE8",
            "WH-UQ09HE8",
            "WH-UQ12HE8",
            "WH-UQ16HE8",
            "WH-UX09HE5",
            "WH-UX09HE8",
            "WH-UX12HE5",
            "WH-UX12HE8",
            "WH-UX16HE8",
            "WH-WDG05LE5",
            "WH-WDG07LE5",
            "WH-WDG09LE5",
            "WH-WXG09ME8",
            "WH-WXG12ME8",
            "WH-WXG16ME8",
            "WH-WXG20ME8",
            "WH-WXG25ME8",
            "WH-WXG30ME8"
        ]
    },
    serialNumber: {
        name: "SERIENUMMER",
        img: {
            linkText: "HVOR SKAL MAN FINDE?",
            path: "/image/checkout/heatpump.png",
        },
    },
    gatewayId: {
        title: "Har du allerrede en netværksadaptor, bedes du indtaste DEVICE ID",
        name: "DEVICE ID (VALGFRIT)",
        img: {
            linkText: "HVOR SKAL MAN FINDE?",
            path: "/image/checkout/deviceID.png"
        }
    },
    shippingAddress: {
        title: "Installationsadresse",
        street: {
            label: "Adresse",
            name: "street",
        },
        zipcode: {
            label: "Postnummer",
            name: "zipcode",
        },
        city: {
            label: "By",
            name: "city",
        },
    },
    btn: {
        back: "TILBAGE TIL MIN KONTO",
        next: "GEM"
    },
    errors: {
        futureDate: "installationsdatoen er i fremtiden",
        dateTooFarInPast: "installationsdatoen er for tidlig",
        onlyDigits: "kun tilladte cifre",
        lengthIs11: "længden af device id skal være 11",
        zipCode: "Præcis 4 cifre",
        serialMin: "Minimum 8 cifre",
        serialMax: "Maksimalt 20 cifre",
        requiredField: "Dette felt er obligatorisk",
        addressTooLong: "Maksimalt 30 tegn",
        cityTooLong: "Maksimalt 35 tegn"
    }
};