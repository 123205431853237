module.exports = {
    home: 'Startseite',
    termsConditions: "Allgemeine Geschäftsbedingungen",
    privacyPolicy: "Datenschutzrichtlinie",
    protectionPlans: 'Servicepaket',
    service: 'Service-Kontakt',
    error: 'Ups, ein Fehler',
    checkout: 'Zur Kasse',
    login: 'Anmelden',
    cancelProtectionPlan: 'Schutzplan kündigen',
    myAccount: 'Mein Konto',
    editMyAccount: 'Persönliche Informationen bearbeiten',
    passwordReset: 'Passwort zurücksetzen',
    upgradeProtectionPlan: 'Servicepaket hochstufen',
    upgrade: 'Hochstufen',
    updatePayment: "Zahlungsart ändern",
    registerUnit: "Wärmepumpengerät registrieren",
    faq : "Häufig gestellte Fragen"
}