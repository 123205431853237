module.exports = {
  success: {
    title: "Vielen Dank!",
    headline: "Ihr Zahlung war erfolgreich!",
    subheadline: "Vielen Dank, ",
    btn: "ZUM KONTO",
  },
  failure: "Ihre Zahlung wurde von unserem Partner abgelehnt.",
  cancel: "Ihre Zahlung wurde storniert.",
  error: "Bei der Zahlung bei unserem Partner ist ein Fehler aufgetreten.",
  expired: "Ihre Zahlungssitzung ist bei unserem Partner abgelaufen.",
  pending: "Ihre Zahlung wird bei unserem Partner noch bearbeitet. Dies kann bis zu 10 Minuten dauern.",
  link: "ZUM KONTO",
  link_href: "/de_AT/home/my_account",
};
