module.exports = {
  headline: "Aquarea Service+ Vertrag",
  baseUnit: "Installiertes Basisgerät",
  contract: "Vertrag",
  month: "Monat",
  monthly: "Monat",
  quarterly: "Vierteljahr",
  annually: "Jahr",
  disclaimerHeadline:
    "Aquarea + Premium Service: nach erfolgreichem Abschluß der Betriebsprüfung",
  disclaimer:
    "Beim Kauf eines Servicepakets „Aquarea+ Premium“ erhalten Sie „Aquarea+ Smart“, bis ein Panasonic-Techniker die Installation überprüft und die Erfüllung der wesentlichen technischen Voraussetzungen bestätigt. Sollten diese technischen Voraussetzungen nicht erfüllt sein, bleiben Sie „Aquarea+ Smart“-Kunde und können die Leistungen dieses Pakets nutzen. Bis zur Freigabe Ihrer Installation für „Aquarea+ Premium“ wird Ihnen der „Aquarea+ Smart“-Preis berechnet.",
  contract_complete: "Dieser Vertrag ist bereits abgeschlossen.",
  contract_cancelled: "Dieser Vertrag wurde gekündigt.",
  waitingForDateUpdateStatus: "Warte auf Antwort vom Zahlungsdienstsystem",
  updateExpiryDate: "Ablaufdatum muss erneuert werden",
  updateFailed:
    "Aktualisierung des Ablaufdatums fehlgeschlagen – Bitte versuchen Sie es später erneut",
  updateSuccess: "Ablaufdatum aktualisiert",
  details: {
    model: "Modell",
    orderNumber: "Bestellnummer",
    installationDate: "Installationsdatum",
    protectionPlan: "Servicepaket",
    location: "Adresse",
    serialNumber: "Seriennummer",
    contractValidTill: "Vertrag gültig bis",
    costs: "Kosten",
    creditCardNumber: "Kreditkartennummer",
    paymentMethod: "Zahlungsmethode",
    expiryDate: "Ablaufdatum",
    expiryValue: "Gültig bis",
  },
  btn: {
    upgrade: "Servicepaket hochstufen",
    cancel: "Servicepaket kündigen",
    payment_update: "Zahlungsart ändern",
    registerHeatpump: "Wärmepumpe registrieren",
    registerYourDevice: "Gerät registrieren",
    expiry_update: "Ablaufdatum aktualisieren",
    updateAllContracts: "Ja Aktualisieren",
    cancelUpdate: "Nein",
  },
  campaign: {
    img: "/image/home/hero/PaSo-GW@2x.png",
    alt: "alt green week img text",
  },
  modal: {
    updateContractsTitle: "Vertrag/Verträge aktualisieren",
    updateContractsBody:
      "Möchten Sie die Ablaufdatum-Aktualisierung auf Ihren/Ihre Vertrag/Verträge anwenden?",
  },
  accountPage: "/de_AT/home/my_account",
};
