module.exports = {
    headline: "Reparaturauftrag",
    thanks: "Vielen Dank, dass Sie sich an den Aquarea+ Service gewandt haben!",
    text: "Ihr Reparaturauftrag wurde erfolgreich abgeschlossen. Nach Abschluss der Zahlung finden Sie Ihre Rechnung in Ihrem E-Mail-Posteingang.",
    invoiceAmountText: "Rechnungsbetrag: ",
    isPaidText: "Ihr Reparaturauftrag wurde erfolgreich abgeschlossen und Ihre Rechnung erfolgreich verarbeitet.",
    noInvoiceText: "Ihr Reparaturauftrag wurde erfolgreich abgeschlossen und Ihre Rechnung erfolgreich verarbeitet.",
    payButton: "Jetzt bezahlen",
    pendingButtonText: "In Bearbeitung",
};
