module.exports = {
    headline: "Wärmepumpengerät registrieren",
    date: {
        title: "Installationsdatum Ihrer Wärmepumpe",
        name: "Installationsdatum"
    },
    model: {
        title: "Welches Modell ist installiert?",
        name: "MODELLNAME",
        img: {
            linkText: "WO FINDEN SIE DAS RICHTIGE MODELL?",
            path: "/image/checkout/heatpump.png",
        },
        emptyOption: "-- Modell auswählen --",
        options: [
            "Ich weiß nicht",
            "WH-MDC05H3E5",
            "WH-MDC05J3E5",
            "WH-MDC07H3E5",
            "WH-MDC07J3E5",
            "WH-MDC09H3E5",
            "WH-MDC09J3E5",
            "WH-MXC09H3E8",
            "WH-MXC09J3E8",
            "WH-MXC12H9E8",
            "WH-MXC12J9E8",
            "WH-MXC16H9E8",
            "WH-MXC16J9E8",
            "WH-MXC16J9E8-1",
            "WH-UD03HE5-1",
            "WH-UD03JE5",
            "WH-UD05HE5-1",
            "WH-UD05JE5",
            "WH-UD07HE5-1",
            "WH-UD07JE5",
            "WH-UD09HE5-1",
            "WH-UD09HE8",
            "WH-UD09JE5",
            "WH-UD09JE5-1",
            "WH-UD12HE8",
            "WH-UD16HE8",
            "WH-UDZ03KE5",
            "WH-UDZ05KE5",
            "WH-UDZ07KE5",
            "WH-UDZ09KE5",
            "WH-UDZ09KE8",
            "WH-UDZ12KE8",
            "WH-UDZ16KE8",
            "WH-UQ09HE8",
            "WH-UQ12HE8",
            "WH-UQ16HE8",
            "WH-UX09HE8",
            "WH-UX12HE8",
            "WH-UX16HE8",
            "WH-UXZ09KE8",
            "WH-UXZ12KE8",
            "WH-UXZ16KE8",
            "WH-WDG05LE5",
            "WH-WDG07LE5",
            "WH-WDG09LE5",
            "WH-WXG09ME8",
            "WH-WXG12ME8",
            "WH-WXG16ME8",
            "WH-WXG20ME8",
            "WH-WXG25ME8",
            "WH-WXG30ME8",
        ]
    },
    serialNumber: {
        name: "Seriennummer",
        img: {
            linkText: "WO FINDEN SIE DAS RICHTIGE MODELL?",
            path: "/image/checkout/heatpump.png",
        },
    },
    gatewayId: {
        title: "Wenn Sie bereits den Netzwerkadapter haben, geben Sie bitte die Geräte-ID ein",
        name: "Geräte-ID (optional)",
        img: {
            linkText: "WO FINDEN SIE DAS RICHTIGE MODELL?",
            path: "/image/checkout/deviceID.png"
        }
    },
    shippingAddress: {
        title: "Installationsadresse",
        street: {
            label: "Adresse",
            name: "street",
        },
        zipcode: {
            label: "Postleitzahl",
            name: "zipcode",
        },
        city: {
            label: "Stadt",
            name: "city",
        },
    },
    btn: {
        back: "zurück zu meinem Konto",
        next: "Speichern"
    },
    errors: {
        futureDate: "Das Installationsdatum liegt in der Zukunft",
        dateTooFarInPast: "Der Installationstermin ist zu früh",
        onlyDigits: "nur Ziffern erlaubt",
        lengthIs11: "Die Länge der Geräte-ID muss 11 betragen",
        zipCode: "Genau 4 Ziffern",
        serialMin: "Min. 8 Ziffern",
        serialMax: "Max. 20 Ziffern",
        requiredField: "Dieses Feld ist erforderlich",
        addressTooLong: "Max. 30 Zeichen",
        cityTooLong: "Max. 35 Zeichen"
    }
};
