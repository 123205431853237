module.exports = {
    headline: "IHRE WAHL:",
    subheadline: "Servicepaket",
    edit_link: "/de_AT/home/service_package",
    edit_label: "BEARBEITEN",
    label: "Installationsservice",
    additionally: [
        {
            label: "Zusätzlich gebucht:",
            text: "1,495.00DKK einmalige Gebühr",
            href_label: "LÖSCHEN",
        }
    ],
    annually: 'jährliche Zahlung',
    quarterly: 'vierteljährliche Zahlung',
    monthly: 'monatliche Zahlung',
    per_month: 'pro Monat',
    disclaimer: 'Wählen Sie im Zahlungsschritt vor der Bestellung die monatliche, vierteljährliche oder jährliche Zahlung aus.'
};
