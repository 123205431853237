module.exports = {
  headline: "Aquarea Service+ Vertrag",
  monthly: "Monat",
  quarterly: "Quartal",
  annually: "Jahr",
  period: "Zeitraum",
  table: {
    headers: {
      date: "DATUM",
      price: "BETRAG",
      status: "STATUS",
    },
  },
  PENDING: "AUSSTEHEND",
  BLOCKED: "GESPERRT",
  PAID: "BEZAHLT",
  CANCELLED: "STORNIERT",
  FAILED: "FEHLGESCHLAGEN",
  PLANNED: "GEPLANT",
  RETRY: "WIEDERHOLEN",
  REFUNDED: "RÜCKERSTATTET",
  OPEN: "OFFEN",
  viewAll: "Alle Rechnungen anzeigen",
  REDUCED_FEE:"Rabatt \"{DISCOUNT_CODE}\" wurde angewendet. Betrag wurde vom Zahlungsplan abgezogen.",
  THREE_MONTHS_FREE: "Rabatt \"{DISCOUNT_CODE}\" wurde angewendet. Betrag wurde vom Zahlungsplan abgezogen.",
  NO_INSTALLATION_FEE: "Rabatt \"{DISCOUNT_CODE}\" wurde angewendet. Betrag wurde vom Zahlungsplan abgezogen.",
  UPGRADE_REDUCED_FEE: "Rabatt \"{DISCOUNT_CODE}\" wurde angewendet. Betrag wurde vom Zahlungsplan abgezogen.",
  UPGRADE_NO_INSTALLATION_FEE: "Rabatt \"{DISCOUNT_CODE}\" wurde angewendet. Betrag wurde vom Zahlungsplan abgezogen.",
};
