// TODO: rename the keys to camelCase
module.exports = {
    headline: "Kassan",
    summary: {
        confirmation: {
            headline: "Bekräftelse",
            subheadline: "Välj paket"
        },
    },
    steps: [
        {
            label: "Kontrollera anslutningen",
            href: "/sv_SE/home/service_package",
            href_label: "Tillbaka till servicepaket",
            next_btn: "Nästa",
            continue_btn: "Fortsätt utan installationstjänst",
            add_installation_items: {
                add_installation_btn: "Lägg till installationstjänsten till",
                add_installation_label: "Viktig information:",
                add_installation_text: 'För att få full nytta av våra servicepaket måste din enhet vara ansluten till internet. <br><br>För att säkerställa att din enhet är ansluten till Internet rekommenderar vi att du beställer vårt installationspaket. Den här tjänsten betalas med ett engångspris ##price##<br><br>Vi lägger automatiskt till installationstjänstpaketet i din köpprocess. Vi ringer dig för att bestämma en tid för installationen.',
                add_installation_text_comfort: 'För att få full nytta av våra servicepaket måste din enhet vara ansluten till internet. <br><br>För att säkerställa att din enhet är ansluten till Internet rekommenderar vi att du beställer vårt installationspaket. Den här tjänsten betalas med ett engångspris ##price##<br><br>Vi lägger automatiskt till installationstjänstpaketet i din köpprocess. Vi ringer dig för att bestämma en tid för installationen.',
            },
            change_plan_items: {
                change_plan_changeid: 2,
                change_plan_btn_sameid: "Nästa",
                change_plan_btn: "Ändra mitt val till Basic",
                change_plan_link: "",
                change_plan_label: "Viktig information:",
                change_plan_text: 'Eftersom din enhet inte är ansluten till internet kan vi inte erbjuda dig alla våra servicepaket för tillfället. För att ge dig bästa möjliga service ändrar vi ditt valda servicepaket till Aquarea Service+ Basic.',
            },
            text: "För att ge dig den bästa servicen ser vi till att dina enheter är anslutna till internet.",
            inputList: [
                {
                    label: "Är enheten ansluten till Internet?",
                    width: "col",
                    name: "internetConnection",
                    startValue: true,
                    hint: {
                        label: "är du inte säker?",
                        text: "test",
                        img: "https://picsum.photos/400/200",
                        img_alt: ""
                    },
                    images: [
                        {path: "/image/checkout/CloudAdapter.jpg", type: "image"}
                    ],
                    inputs: {
                        checkboxes: [
                            {
                                label: "Ja",
                                key: "internetConnected",
                                defaultValue: true,
                                value: true,
                            },
                            {
                                label: "Nej",
                                key: "internetConnected",
                                defaultValue: false,
                                value: false,
                            }
                        ]
                    }
                },
                {
                    label: "Vilken typ av fjärrkontroll har du?",
                    width: "col",
                    name: "unitForInternet",
                    startValue: true,
                    hint: {
                        label: "är du inte säker?",
                        text: "",
                        img: "",
                        img_alt: ""
                    },
                    images: [
                        {path: "/image/checkout/NewController.jpg", type: "label"},
                        {path: "/image/checkout/OldController.jpg", type: "label"},
                    ],
                    inputs: {
                        checkboxes: [
                            {
                                label: "Ja",
                                key: "internetReady",
                                defaultValue: false,
                                value: true
                            },
                            {
                                label: "Nej",
                                key: "internetReady",
                                defaultValue: false,
                                value: false
                            }
                        ]
                    }
                },
                {
                    label: "Är din värmepump yngre än 5 år?",
                    width: "col",
                    name: "baseUnitAge",
                    startValue: true,
                    inputs: {
                        checkboxes: [
                            {
                                label: "Ja",
                                key: "baseUnitAge",
                                defaultValue: true,
                                value: true,
                            },
                            {
                                label: "Nej",
                                key: "baseUnitAge",
                                defaultValue: false,
                                value: false,
                            }
                        ]
                    }
                }
            ],
        },
        {
            label: "Kontoinformation",
            href: "/sv_SE/home/service_package",
            href_label: "Tillbaka",
            next_btn: "Nästa",
            inputList: [
                {
                    headline: "Skapa ett nytt konto",
                    login: "LOGGA IN",
                    login_path: "",
                    login_label: "Befintlig användare?",
                    inputs: [
                        [{
                            label: "E-post",
                            name: "email",
                            placeholder: "",
                            type: "email",
                            validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH", "EMAIL"],
                            constraints: {minLength: 3, maxLength: 80},
                            width: "col-12 col-lg-6",
                            checkId: "email",
                            validation: "matching_email"
                        },
                            {
                                label: "Upprepa e-post",
                                name: "repeat-email",
                                placeholder: "E-post",
                                type: "email",
                                validators: ["REQUIRED", "COMPARE"],
                                validationMessages: {
                                    COMPARE: "E-postmeddelandet måste vara detsamma.",
                                },
                                constraints: {compareControl: "email"},
                                width: "col-12 col-lg-6",
                                checkId: "email",
                            }],
                        [{
                            label: 'Lösenord <span>Min. 8 tegn, tal og bogstaver</span>',
                            name: "password",
                            placeholder: "",
                            type: "password",
                            validators: ["REQUIRED", "MIN_LENGTH","PASSWORD"],
                            constraints: {minLength: 8},
                            width: "col-12 col-lg-6",
                            checkId: "password",
                        },
                            {
                                label: "Upprepa lösenordet",
                                name: "repeat-password",
                                placeholder: "Lösenord",
                                type: "password",
                                validators: ["REQUIRED", "COMPARE"],
                                validationMessages: {
                                    COMPARE: "Lösenordet måste vara detsamma som",
                                },
                                constraints: {compareControl: "password", minLength: 8},
                                width: "col-12 col-lg-6",
                                checkId: "password",
                            }]
                    ]
                },
                {
                    headline: "Kontoadress",
                    inputs: [
                        [{
                            label: "Förnamn",
                            name: "firstName",
                            placeholder: "",
                            type: "text",
                            validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
                            constraints: {minLength: 2, maxLength: 19},
                            width: "col-12 col-lg-6",
                            validation: "required"
                        },
                            {
                                label: "Efternamn",
                                name: "lastName",
                                placeholder: "",
                                validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
                                constraints: {minLength: 2, maxLength: 19},
                                type: "text",
                                width: "col-12 col-lg-6",
                                validation: "required"
                            }],
                        [{
                            label: "Adress",
                            name: "street",
                            placeholder: "",
                            type: "text",
                            validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
                            constraints: {minLength: 1, maxLength: 30},
                            width: "col"
                        }],
                        [{
                            label: "Postnummer",
                            name: "zipCode",
                            placeholder: "",
                            validators: ["REQUIRED", "SWEDISH_ZIPCODE"],
                            type: "text",
                            width: "col-6"
                        },
                            {
                                label: "Ort",
                                name: "city",
                                placeholder: "",
                                validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
                                constraints: {minLength: 1, maxLength: 35},
                                type: "text",
                                width: "col-6"
                            },
                            {
                                label: "LAND <img src='/image/menu/locales/sweden.jpg' style='width: 1.5em'/> <br> <span> Detta servicepaket och dessa villkor gäller för enheter som är installerade i det aktuella landet. Om din värmepump är installerad i ett annat land, välj landet längst upp till höger på sidan, det visar de länder där Aquarea Service+ är tillgängligt.</span>",
                                name: "country",
                                type: "country",
                                width: "col-12"
                            }],
                        [{
                            label: "Telefonnummer",
                            name: "phoneNumber",
                            key: "phoneNumber",
                            placeholder: "",
                            validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
                            constraints: {minLength: 9, maxLength: 17},
                            type: "phone",
                            width: "col",
                            offset:3
                        }]
                    ]
                },
            ],
        },
        {
            label: "Betalningsinformation",
            href: "/sv_SE/home/service_package",
            href_label: "Tillbaka",
            subheadline: "Betalningsmodell för avtal",
            per_month:"per månad",
            pay: "Betala",
            monthly:"månadsvis",
            quarterly:"kvartalsvis",
            annually:"årligen",
            discount: {
                name: "Rabatt",
                placeholder: "infoga rabattkod",
                button: "tillämpa",
                remove: "ta bort",
                invalidHeadline: "Den angivna koden är ogiltig",
                invalidHint: "Kontrollera dina uppgifter",
                success: "Din rabattkod har godkänts och kommer automatiskt att tillämpas på ditt köp. Observera att inget avdrag kommer att vara synligt i det totala beloppet i kassan. Du kan se avdraget på ditt konto efter köpet, under dina schemalagda betalningar.",
                errors: {
                    tooLong: "Den angivna rabattkoden är för lång.",
                    invalidInput: "Ingen rabatt angiven"
                }
            },
            text: "Vänligen välj din betalningsmetod. Betalningen kommer att behandlas efter att du har betalat.",
            disclaimer: "Vänligen notera att en Användare som beställer ett Premium-abonnemang kommer att få ett Smart-abonnemang tills dess att Panasonic har verifierat och kontrollerat installationen. När det bekräftats att de huvudsakliga tekniska kraven har uppfyllts kan Panasonic bevilja användarens ansökan om Premium-abonnemang. Om Användaren inte uppfyller de tekniska kraven för Premium-abonnemanget kommer Användaren fortsatt kunna nyttja Smart-abonnemangets fördelar. Användaren kommer initial att debiteras avgiften för Smart-abonnemanget och om Användarens ansökan om Premium-abonnemang beviljas av Panasonic kommer Användaren fortsättningsvis att debiteras avgiften för Premium-abonnemanget.",
            credit_secure: "Observera att vår plattform endast accepterar kreditkort som är 3D Secure-aktiverade.\n" +
                "3D Secure är ett extra steg i valideringen av betalningsprocessen, där kortinnehavaren måste ange en kod som har\n" +
                "skickats till mobiltelefonen som en del av utcheckningsprocessen. \n" +
                "För att registrera ditt kreditkort för detta, vänligen kontakta din bank eller kreditkortsutgivare direkt, eller kolla deras webbplats för ytterligare instruktioner. \n" +
                "Alternativt kan du betala med PayPal.\n",
            visa_secure: "/image/icons/checkout/visaSecure.png",
            visa_secure_alt: "visa secure",
            mastercard_id_check: "/image/icons/checkout/mastercardIdCheckBlack.png",
            mastercard_id_check_alt: "mastercard id check",
            agreement: "Jag bekräftar att jag har läst och godkänner <a href=\"/sv_SE/home/terms_and_conditions\" target=\"_blank\">villkoren för Aquarea Service+</a> och att jag är medveten om <a href=\"/sv_SE/home/privacy_policy\" target=\"_blank\">integritetspolicyn för Aquarea Service+.</a>",
            policy: "Jag begär och samtycker till att Panasonic påbörjar den avtalade tjänsten innan ångerfristen löper ut.\nJag kommer att vara skyldig att betala för alla tjänster inklusive reparations- och underhållstjänster som utförts innan jag utövar min 14 dagars ångerrätt.",
            campaign: "Jag samtycker till att få ett separat e-postmeddelande från Treedom med en kod för att påbörja planteringen av mitt träd.",
            next_btn: "Nästa",
            inputList: [
                {
                    headline: "Ange betalningsuppgifter",
                    inputs: [
                        {
                            label: "Betalningsmetod",
                            name: "payment_opt",
                            type: "payment_opt",
                            payments: [
                                {
                                    id: "0",
                                    label: "Betalningsmetod",
                                    key: "paymentOpt",
                                    value: "kredit",
                                    image: "/image/icons/checkout/credit.png",
                                    isChecked: false
                                },
                                {
                                    id: "1",
                                    label: "Betalningsmetod",
                                    key: "paymentOpt",
                                    value: "paypal",
                                    image: "/image/icons/checkout/paypal.png",
                                    isChecked: false
                                },
                            ],
                            width: "col"
                        },
                        {
                            label: "KORTNUMMER",
                            name: "card_number",
                            placeholder: "",
                            type: "text",
                            width: "col"
                        },
                        {
                            label: "UTGÅNGSDATUM.",
                            name: "expiration_date",
                            placeholder: "Utgångsdatum",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            label: "CVV",
                            name: "cvv",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            labels: [
                                {
                                    id: "datarättigheter",
                                    label: "Bekräfta att du har läst och godkänner villkoren i Aquarea Service+ Customer Agreement.",
                                    name: "datarättigheter",
                                    value: "",
                                    width: "col"
                                },
                            ],
                            type: "radio",
                            width: "col",
                        }
                    ]
                },
            ],
        },
        {
            label: "Bekräftelse",
            href: "/protection_plans",
            href_label: "Tillbaka",
            next_btn: "Nästa",
            inputList: [
                {
                    headline: "Skapa ett nytt konto",
                    inputs: [
                        {
                            label: "EMAIL",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            label: "LÖSENORD",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        }
                    ]
                },
                {
                    headline: "Kontoadresse",
                    inputs: [
                        {
                            label: "KUNDTYP",
                            name: "customer",
                            placeholder: "Privat",
                            type: "select",
                            width: "col col-lg-6",
                            options: [
                                "Private",
                                "test1",
                                "test2",
                                "test3",
                                "test4",
                                "test5",
                                "test6"
                            ]
                        },
                        {
                            label: "FORNAVN",
                            name: "first_name",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            label: "EFTERNAVN",
                            name: "last_name",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            label: "ADRESS",
                            name: "street",
                            placeholder: "",
                            type: "text",
                            width: "col"
                        },
                        {
                            label: "POSTNUMMER",
                            name: "zipCode",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            label: "BY",
                            name: "city",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            label: "TELEFON",
                            name: "phoneNumber",
                            placeholder: "",
                            type: "text",
                            width: "col"
                        }
                    ]
                }
            ],
        }
    ]
}

