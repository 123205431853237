import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { AuthContext } from '../../lib/contexts/authContext';
import { getAccount } from "../../lib/dataAccess/accountService";
import { ErrorResponseHandling } from "../../lib/dataAccess/errorResponseHandling";
import PlanComparisonTable from '../planComparisonTable';
import i18n from '../../lib/i18n';
import { ProductContext } from '../../lib/contexts/productContext';
import { PageBreadcrumb, PageHeader, PageHeadline } from '../layout/page';
import { DetailContainer, DetailEntry, DetailHeadline, DetailList } from '../layout/detailEntry';
import { getUpgradeOptionsForOrder } from '../../lib/dataAccess/checkout/orderRestEndpoint';
import { UpgradeContractContext } from '../../lib/contexts/upgradeContractContext';
import { getPriceForSku, getProductByVariant, getPaymentPeriodForSku } from '../../lib/helper/productHelper';
import { AUTH_TOKEN_SESSION_STORAGE_NAME,
    CHOSEN_UPGRADE_SKU_SESSION_STORAGE_NAME,
    ORDER_ID_TO_UPGRADE_SESSION_STORAGE_NAME,
    CHOSEN_UPGRADE_VARIANT_SKU_SESSION_STORAGE_NAME,
    COUNTRY_CODE_NAME,
    getSessionStorageItem,
    setSessionStorageItem,
} from '../../lib/sessionStorage';

const countryCode = getSessionStorageItem(COUNTRY_CODE_NAME) || 'en_IE';
const t = i18n.namespace("UpgradeChoices");

function UpgradeChoices() {
    const { authContext, setAuthContext } = useContext(AuthContext);
    const { setUpgradeContractContext } = useContext(UpgradeContractContext);
    const history = useHistory();
    const { productContext } = useContext(ProductContext);
    const [upgradablePlansSkus, setUpgradablePlansSkus] = useState(null);
    const [feeRequired, setFeeRequired] = useState(true);
    const [activePlan, setActivePlan] = useState(null);
    const [tryReloadAuthContext, setTryReloadAuthContext] = useState(false);
    const [alreadyLoadedUpgradeOptions, setAlreadyLoadedUpgradeOptions] = useState(false);
    let { orderId } = useParams();

    useEffect(() => {
        if (authContext?.accountData && !alreadyLoadedUpgradeOptions) {
            getUpgradeOptionsForOrder(orderId, authContext.authToken).then(res => {
                setFeeRequired(res.data.feeRequired);
                setUpgradablePlansSkus(res.data.possibleSkus);
                setAlreadyLoadedUpgradeOptions(true);
            }).catch(e => {
                ErrorResponseHandling(e, authContext, setAuthContext, history);
            });
        }
        const activePlan = { ...getActivePlanFromAuthContext() };
        setActivePlan({
            sku: activePlan.sku,
            product: getProductByVariant(productContext, activePlan.sku),
            name: activePlan.name,
            totalGross: {
                amount: activePlan.totalGross?.amount,
                currency: activePlan.totalGross?.currency
            }
        });
    }, [authContext, alreadyLoadedUpgradeOptions, productContext]) // eslint-disable-line react-hooks/exhaustive-deps

    const refreshAccountContext = async () => {
        await getAccount(getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME)).then((result) => {
            setAuthContext({...authContext, accountData: {...authContext.accountData, ...result.data}});
        });
    };

    const getActivePlanFromAuthContext = () => {
        let installedBaseUnitWithOrderToUpgrade;
        if (authContext?.accountData) {
            installedBaseUnitWithOrderToUpgrade = authContext.accountData.installedBaseUnits
            .find(unit => unit?.orderData?.orderId === orderId);
        };
        //try to reload auth context when contract with baseUnit not found
        if(!installedBaseUnitWithOrderToUpgrade && !tryReloadAuthContext){
            setTryReloadAuthContext(true);
            refreshAccountContext();
            return null;
        }
        if(!installedBaseUnitWithOrderToUpgrade){
            return null;
        }
        return installedBaseUnitWithOrderToUpgrade.orderData.lineItems.find(lineItem => lineItem.sku.startsWith("contract-"));
    };

    const handleUpgradeButtonClick = (event, upgradeSku, upgradeVariantSku) => {
        event.preventDefault();

        setUpgradeContractContext({
            upgradeSku,
            upgradeVariantSku,
            orderId,
        });

        setSessionStorageItem(ORDER_ID_TO_UPGRADE_SESSION_STORAGE_NAME, orderId);
        setSessionStorageItem(CHOSEN_UPGRADE_SKU_SESSION_STORAGE_NAME, upgradeSku);
        setSessionStorageItem(CHOSEN_UPGRADE_VARIANT_SKU_SESSION_STORAGE_NAME, upgradeVariantSku);
        history.push('/'+ countryCode +'/home/upgrade/checkout');
    }

    const getPaymentPeriod = () => {
     return activePlan && activePlan.sku ? getPaymentPeriodForSku(activePlan.sku, productContext) : '';
    };

    return (
        <div>
            <div className="page">
                <PageHeader>
                    <PageBreadcrumb />
                    <PageHeadline headline={t("headline")} />
                </PageHeader>
                <div className="page_body">
                    <section className="upgrade-choices-overview">
                        <DetailContainer>
                            <DetailList>
                                <DetailHeadline headline={t("currentProtectionPlan")} />
                                <DetailEntry label={t("protectionPlan") + ":"}>
                                    {activePlan && activePlan.name}
                                </DetailEntry>
                                <DetailEntry label={t("costs") + ":"}>
                                    {activePlan?.sku && getPriceForSku(activePlan.sku, productContext, countryCode)}
                                </DetailEntry>
                                <DetailEntry label={t("period") + ":"}>
                                    {activePlan?.sku && t(getPaymentPeriod())}
                                </DetailEntry>
                            </DetailList>
                        </DetailContainer>
                        <PlanComparisonTable
                            isUpgradeMode={true}
                            upgradablePlansSkus={upgradablePlansSkus || []}
                            activePlanSku={activePlan && activePlan.product ? activePlan.product.sku : null}
                            paymentPeriod={getPaymentPeriod()}
                            onUpgradeButtonClick={handleUpgradeButtonClick}
                            feeRequired={feeRequired}
                        />
                        <div className="page-align-center">
                            <Link className="btn-secondary" to={'/'+ countryCode +'/home/my_account'}>
                                {t("backButtonText")}
                            </Link>
                        </div>
                    </section>
                </div>
            </div>
        </div >
    )
}

export default UpgradeChoices;
