module.exports = {
  headline: "Upgrade-Optionen",
  currentProtectionPlan: "Aktueller Schutzplan",
  protectionPlan: "Schutzplan",
  validUntil: "Vertrag gültig bis",
  costs: "Kosten",
  period: "Zahlungszeitraum",
  monthly: "Monatlich",
  quarterly: "Vierteljährlich",
  annually: "Jährlich",
  notUpgradableText: "Sie können Ihren aktuellen Vertrag nicht upgraden.",
  backButtonText: "zurück zu meinem Konto",
};
