module.exports = {
    reports: "Berichte",
    headline: "Aquarea Service+ Betriebsbericht",
    table: {
        headers: {
            date: "Datum",
            report: "Betriebsbericht",
        },
        showAll: "alle Berichte anzeigen",
        hideOlder: "alte Berichte ausblenden"
    },
    download: "Laden Sie den Bericht hier herunter"
}
