const languageList = ['sv', 'it', 'es', 'en', 'da', 'de'];
const countryList = ['SE', 'IT', 'ES', 'IE', 'DK', 'DE', 'AT'];
const validCountryCodes = ['sv_se','it_it','es_es','en_ie','da_dk','en_es','de_de','de_at'];
const correctableCountryCodes = new Map();
correctableCountryCodes.set('se_sv', 'sv_SE');
correctableCountryCodes.set('ie_en', 'en_IE');
correctableCountryCodes.set('dk_da', 'da_DK');
correctableCountryCodes.set('at_de', 'de_AT');

const defaultLanguage = 'en';
const defaultCountry = 'IE';
const defaultCountryCode = 'eu_EU';


export function getLanguageForCountryCode(countryCode) {
    if (countryCode === undefined || countryCode === null || countryCode.length < 5) {
        return defaultLanguage;
    }
    let languageCode = countryCode.slice(0,2).toLowerCase()
    if (process.env.REACT_APP_LOCAL_DE_ACTIVE === "true") {
        if (languageList.includes(languageCode)) {
            return languageCode
        }
    }
    return defaultLanguage;
}

export function getCountryForCountryCode(countryCode) {
    if (countryCode === undefined || countryCode === null || countryCode.length < 5) {
        return defaultCountry;
    }
    let country = countryCode.slice(3,5).toUpperCase()
    if (process.env.REACT_APP_LOCAL_DE_ACTIVE === "true") {
        if (countryList.includes(country)) {
            return country
        }
    }
    return defaultCountry;
}

export function getFormattedCountryCode(countryCode){
    if (countryCode === undefined || countryCode === null || countryCode.length < 5) {
        return defaultCountryCode;
    }
    if(validCountryCodes.includes(countryCode.toLowerCase())){
         return countryCode;
    }
    let correctedCountryCode = correctableCountryCodes.get(countryCode.toLowerCase());
    return correctedCountryCode ? correctedCountryCode : defaultCountryCode;
}