module.exports = {
  headline: "Großartige Service- und Hilfeleistungen",
  text: "Die Aquarea Service+ Pakete umfassen viele Vorteile, sodass für jeden Bedarf die passende Wahl dabei ist. Einige der Vorteile sind:",
  list: [
    {
      iconUrl: "/image/icons/services/monitoring.png",
      iconAlt: "",
      headline: "IoT-Überwachung rund um die Uhr",
      text: "Die Parameter Ihres Aquarea-Systems werden überwacht und stehen zur Verfügung, um Hilfeleistungen über die Fernüberwachung zu ermöglichen."
    },
    {
      iconUrl: "/image/icons/services/alert_notification.png",
      iconAlt: "",
      headline: "Intelligente Aquarea-Berichte",
      text: "Erhalten Sie individuelle Berichte über Ihr Heizsystem, die Ratschläge und Tipps zur Verbesserung von Leistung und Effizienz enthalten."
    },
    {
      iconUrl: "/image/icons/services/maintenance_visits.png",
      iconAlt: "",
      headline: "Jährlicher Wartungsbesuch",
      text: "Alle Leistungspakete beinhalten einen jährlichen Wartungsbesuch Ihrer Aquarea Wärmepumpe durch einen Panasonic Service+ Techniker."
    },
    {
      iconUrl: "/image/icons/services/247_hotline.png",
      iconAlt: "",
      headline: "Tägliche Diagnoseprüfung",
      text: "Das Panasonic Service+ Team überprüft Ihr System täglich, um es auf abnormale Parameter zu überwachen."
    },
    {
      iconUrl: "/image/icons/services/24h_response_time.png",
      iconAlt: "",
      headline: "24/7 Priority Support Hotline",
      text: "Rufen Sie uns rund um die Uhr an, wenn Sie Fragen zu Ihrer Wärmepumpe haben."
    },
    {
      iconUrl: "/image/icons/services/repair_discount.png",
      iconAlt: "",
      headline: "24/7 Reaktionszeit",
      text: "Ihr Gerät wird innerhalb von 24 Stunden entweder aus der Ferne oder per Hausbesuch unterstützt."
    }
  ]
};
