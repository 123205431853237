module.exports = {
    upgradeDiscount: {
        name: "Rabatt",
        inputPlaceholder: "Rabattcode eingeben",
        applyBtnLable: "ANWENDEN",
        removeBtnLable: "Entfernen",
        invalidHeadline: "Der eingegebene Rabattcode ist ungültig",
        invalidHint: "Bitte überprüfen Sie Ihre Eingabe",
        errors: {
            tooLong: "Der eingegebene Rabattcode ist zu lang.",
            invalidInput: "Der eingegebene Rabattcode ist ungültig" // upgrade page
        }
    }
};