module.exports = {
    details: {
        title: "Titel",
        firstName: "Vorname",
        lastName: "Nachname",
        name: "Name",
        email: "E-Mail",
        address : "Adresse",
        zipCode : "Postleitzahl",
        city: "Stadt",
        phoneNumber: "Telefonnummer",
        phone : "Telefon",
    },
    btn: {
        edit: "Persönliche Informationen bearbeiten",
        back: "zurück zu meinem Konto",
        update: "Speichern",
        reset: "Passwort zurücksetzen"
    },
    editAccount: {
        headline: "Persönliche Informationen bearbeiten"
    },
    loading: "Laden..."
}
