import React, {useContext, useEffect, useState} from 'react'
import {useHistory} from "react-router-dom";
import i18 from "../lib/i18n";
import Menu from "../components/menu";
import Footer from "../components/footer";
import {Link} from "react-router-dom";
import Breadcrumbs from "../lib/breadcrumbs";
import {BtnGroup, StandardButton} from "../components/layout/buttons";
import {deleteOrderRequest, queryOrderDowngradeOptions, markOrderWithDowngradeInfo} from "../lib/dataAccess/checkout/orderRestEndpoint"
import { ErrorResponseHandling } from "../lib/dataAccess/errorResponseHandling";
import {AUTH_TOKEN_SESSION_STORAGE_NAME, COUNTRY_CODE_NAME, getSessionStorageItem} from "../lib/sessionStorage";
import {scrollToPos} from "../lib/scrollToPos";
import {AuthContext} from "../lib/contexts/authContext";

const countryCode = getSessionStorageItem(COUNTRY_CODE_NAME) || 'da_DK';
const t = i18.namespace("MyAccountCancelProtectionPlan");

function CancelProtectionPlan(props) {
    let [orderId, setOrderId] = useState("")
    const [getQuerys, setQuerys] = useState({})
    const [downgradeOptions, setDowngradeOptions] = useState([])
    let [selectedDowngradeOption, setSelectedDowngradeOption] = useState(null)
    const {authContext, setAuthContext} = useContext(AuthContext);
    let history = useHistory();

    useEffect(() => {
        const query = new URLSearchParams(props.location.search);
        const getSku = query.get("sku");
        const getOrderId = query.get("orderId");
        setOrderId(getOrderId)
        const optionPromise = getOrderDowngradeOptions(getOrderId);
        optionPromise.then( (data) => {
            setDowngradeOptions(data)
        });
        setQuerys({sku: getSku, orderId: getOrderId})
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        scrollToPos(0, 0);
    }, [])

    async function cancelProtection() {
        try {
            await deleteOrderRequest(getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME), getQuerys.orderId, getQuerys.sku)
                .then(res => {
                    if (res.status) {
                        history.push("/" + countryCode + "/home/my_account");
                    }
                })
        } catch (e) {
            ErrorResponseHandling(e, authContext, setAuthContext, history);
        }
    }

    async function downgradeOrder() {
        try {
            if (selectedDowngradeOption == null) {
                return;
            }
            await markOrderWithDowngradeInfo(
                getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME),
                orderId,
                selectedDowngradeOption)
                .then(res => {
                    if (res.status === 201 || res.status === 200 ) {
                        // Order was marked with Downgrade
                        history.push("/" + countryCode + "/home/my_account");
                    }
                })
        } catch (ex) {
            ErrorResponseHandling(ex, authContext, setAuthContext, history);
            history.push("/" + countryCode + "/home/my_account");
        }
    }


    function getOrderDowngradeOptions(orderId) {
        // fragen an api welche downgrade optionen gibt für Order
        try {
            return queryOrderDowngradeOptions(getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME), orderId)
                .then(res => {
                    return res.data;
                })
        } catch (e) {
            ErrorResponseHandling(e, authContext, setAuthContext, history);
        }
    }

    function isDowngradePossible() {
         return ! (downgradeOptions === undefined || downgradeOptions.length === 0);
    }

    function optionsTanslationKey(index) {
        // convert 'contract-heatpump-silver' to 'silver'
        // resulting key example "MyAccountCancelProtectionPlan.downgradeOptions.silver"
        return "downgradeOptions." + downgradeOptions[index].split("-")[2]
    }

    function renderOptions() {
        return <div className={"downgradeOptions"}>
                {downgradeOptions && downgradeOptions.map((option, index) => {
                    return <div className={"option" + index} key={"downgradeOptionInput" + index}>
                        <input className="form-check-input"
                               id={"downgradeOption" + index} type="radio"
                               value={option}
                               checked={selectedDowngradeOption === option} onChange={(event) =>
                            setSelectedDowngradeOption(event.target.value)}/>
                        <label className="form-check-label">
                            {t(optionsTanslationKey(index))}
                        </label>
                    </div>
                })}
        </div>
    }

    return ( <div className="page">
                <Menu/>
                <div className="container">
                    <div className="page_header container">
                        <section className="breadcrumbs">
                            <Breadcrumbs/>
                        </section>
                    </div>
                    <div className="homepage_body">
                        <div className="cancel">
                            <div className="container">
                                <p className="cancel_title">{t("title")}</p>

                                { isDowngradePossible() ?
                                    <div className="cancel_wrapper">

                                        <div className={"cancel_menu"}>
                                            <p className="cancel_headline">{t("downgradeSectionTitle")}</p>
                                            <p className="cancel_text">{t("downgradeText")}</p>

                                            <div className="form-check">
                                                {renderOptions()}
                                            </div>
                                        </div>

                                        <BtnGroup className="justify-content-center">
                                            <Link className="btn-secondary" to={"/" + countryCode + "/home/my_account"}>
                                                {t("btnReturnToProfile")}
                                            </Link>
                                            <StandardButton isEnabled={selectedDowngradeOption !== null} onClick={downgradeOrder} text={t("btnDowngradeOrder")} classNames={"btn-primary"}/>
                                        </BtnGroup>

                                    </div>
                                    : <></>
                                }
                            </div>
                        </div>

                        <div className={"cancel"}>
                            <div className={"container"}>
                                <div className="cancel_wrapper">
                                    <div className={"cancel_menu"}>
                                        <p className="cancel_headline">{t("cancelSectionTitle")}</p>
                                        <p className="cancel_text">{t("cancelText")}</p>
                                    </div>

                                    <BtnGroup className="justify-content-center">
                                        <Link className="btn-secondary" to={"/"+ countryCode +"/home/my_account"}>
                                            {t("btnReturnToProfile")}
                                        </Link>
                                        <button className="btn-primary" onClick={cancelProtection}>
                                            {t("btnCancelSubscription")}
                                        </button>
                                    </BtnGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
        </div>
    )
}

export default CancelProtectionPlan;
