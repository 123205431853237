import React, { useContext, useEffect, useState } from "react";
import { ProductContext } from "../lib/contexts/productContext";
import {InstallationFeeContext} from "../lib/contexts/installationFeeContext";
import { getMinMonthlyPriceForSku, getPriceForContractByPaymentPeriod, getVariantSkuForPeriod } from "../lib/helper/productHelper";
import i18n from "../lib/i18n";
import { StandardButton } from "./layout/buttons";
import { OptionalValue } from "./layout/detailEntry";
import {
	getSessionStorageItem,
	COUNTRY_CODE_NAME,
} from "../lib/sessionStorage";
import { formatMoneyByCountryCode } from "../lib/helper/moneyHelper";

const t = i18n.namespace("PlanComparisonTable");

function PlanComparisonTable({ isUpgradeMode, upgradablePlansSkus, activePlanSku, paymentPeriod, onUpgradeButtonClick, feeRequired }) {
	const [upgradablePlanIndexes, setUpgradablePlanIndexes] = useState([]);
	const [activePlanIndex, setActivePlanIndex] = useState(null);
	const { productContext } = useContext(ProductContext);
	const { installationFeeContext } = useContext(InstallationFeeContext);
	const countryCode = getSessionStorageItem(COUNTRY_CODE_NAME) || 'en_IE';

	useEffect(() => {
		if (isUpgradeMode) {
			setUpgradablePlanIndexes(mapUpgradableSkusToPlanIndexes(upgradablePlansSkus));
		}
	}, [upgradablePlansSkus]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (isUpgradeMode) {
			setActivePlanIndex(mapSkuToIndex(activePlanSku));
		}
	}, [activePlanSku]) // eslint-disable-line react-hooks/exhaustive-deps

	const mapUpgradableSkusToPlanIndexes = (upgradableSkus) => {
		let upgradableIndexes = [];

		if (upgradableSkus.includes('contract-heatpump-bronze')) {
			upgradableIndexes.push(0);
		}
		if (upgradableSkus.includes('contract-heatpump-silver')) {
			upgradableIndexes.push(1);
		}
		if (upgradableSkus.includes('contract-heatpump-gold')) {
			upgradableIndexes.push(2);
		}

		return upgradableIndexes;
	}

	const mapSkuToIndex = (sku) => {
		if (sku === 'contract-heatpump-bronze') {
			return 0;
		}
		if (sku === 'contract-heatpump-silver') {
			return 1;
		}
		if (sku === 'contract-heatpump-gold') {
			return 2;
		}
	}

	const featureEnabled = (plan) => {
		return !!plan;
	};

	const getPlanValue = (value) => {
		if (!featureEnabled(value)) {
			return null;
		}

		if (typeof value === "boolean") {
			return <img className="plan-comparison_table_feature_icon" src={t("feature.includedIcon.src")} alt={t("feature.includedIcon.alt")} />
		}

		if (value === "doubletick") {
			return <div className={"icon-wrapper"}>
				<img className="plan-comparison_table_feature_icon" src={t("feature.includedIcon.src")} alt={t("feature.includedIcon.alt")} />
				<img className="plan-comparison_table_feature_icon" src={t("feature.includedIcon.src")} alt={t("feature.includedIcon.alt")} />
			</div>
		}

		return <span>{value}</span>
	};

	const isColumnDisabled = (index) => {
		return isUpgradeMode && !upgradablePlanIndexes.includes(index);
	}

	const getTableCellClassNames = (index, initialClassNames) => {
		return isColumnDisabled(index) ? initialClassNames + " disabled-column" : initialClassNames;
	}

	const getPrice = (productSku) => {
		if(!paymentPeriod){
			return getMinMonthlyPriceForSku(productSku, productContext, countryCode);
		} else {
			return getPriceForContractByPaymentPeriod(productSku, paymentPeriod, productContext, countryCode);
		}
	}

	return (
		<section className="plan-comparison container_for_desktop" id="plan-comparison-table">
			<div className="section-content section-content--indented">
				<h2 className="plan-comparison_headline">
					{t("headline")}
				</h2>
				<div className="table-responsive">
					<table className="table table--align-center plan-comparison_table">
						<thead>
							{isUpgradeMode &&
								<tr>
									<th className="plan-comparison_table_header_hint" />
									{t("header").plan.map((plan, i) =>
										i === activePlanIndex
											? (
												<th key={i} className="plan-comparison_table_header_hint plan-comparison_table_header_hint_active">
													{t("activePlanHint")}:
												</th>)
											: (<th key={i} className="plan-comparison_table_header_hint" />)
									)}
								</tr>
							}
							<tr>
								<td className="plan-comparison_table_header text advantages">{t("advantages")}</td>
								{t("header").plan.map((plan, i) =>
									<th key={i} className={getTableCellClassNames(i, "plan-comparison_table_header")}>
										<img className="plan-comparison_table_header_image" src={plan.path} alt={plan.alt} />
									</th>
								)}
							</tr>
						</thead>
						<tbody>
							{t("feature").list.map((feature, i) =>
								<tr key={i}>
									<td className="plan-comparison_table_feature plan-comparison_table_feature_name">
										{feature.label}
									</td>
									{feature.included.map((plan, i) =>
										<td
											key={i}
											className={featureEnabled(plan)
												? getTableCellClassNames(i, 'plan-comparison_table_feature plan-comparison_table_feature--enabled')
												: getTableCellClassNames(i, 'plan-comparison_table_feature')}
										>
											{getPlanValue(plan)}
										</td>
									)}
								</tr>
							)}
							<tr>
								<td className={"plan-comparison_table_feature_footer" + t("explanation.layout")}>
									{t("explanation.text")}
								</td>
								{t("planAbo").map((abo, i) =>
									<td key={i} className={getTableCellClassNames(i, "plan-comparison_table_feature_footer")}>
										<OptionalValue condition={!paymentPeriod} emptyValue="">
											<span className="plan-comparison_table_feature_footer_subtitle">{t("startingAt")}</span>
										</OptionalValue>
										<strong className="plan-comparison_table_feature_footer_price">{getPrice(abo.sku)}</strong>
										<span className="plan-comparison_table_feature_footer_subtitle" dangerouslySetInnerHTML={{ __html: abo[paymentPeriod || 'monthly'] }} />
										<br/>
										{feeRequired && !isColumnDisabled(i) &&
											<span className="plan-comparison_table_feature_footer_subtitle">{t("plusFee") + " "} <br/><strong className="plan-comparison_table_feature_footer_price"> {formatMoneyByCountryCode(installationFeeContext?.totalGross?.amount, countryCode)}</strong></span>
										}
									</td>
								)}
							</tr>
							<tr>
								{!!t("productCode") && t("productCode").map((productCode, i) =>
									<td key={i} className={"plan-comparison_table_feature" + productCode.layout}>
										{productCode.code}
									</td>
								)}
							</tr>
							{isUpgradeMode &&
								<tr>
									<th className="plan-comparison_table_feature_footer" />
									{t("planAbo").map((abo, i) =>
										<td key={i} className={getTableCellClassNames(i, "plan-comparison_table_feature_footer")}>
											<StandardButton
												isEnabled={!isColumnDisabled(i)}
												onClick={(event) => onUpgradeButtonClick(event, abo.sku, getVariantSkuForPeriod(abo.sku, paymentPeriod, productContext))}
												text={i === activePlanIndex ? t("buttonTextActive") : t("buttonTextUpgrade")}
												classNames="btn-primary order-1 order-md-2"
											/>
										</td>
									)}
								</tr>
							}
						</tbody>
					</table>
					<span className={"annotation"}>{t("annotation")}</span>
				</div>
				<div className={"section-content-premium-text"}>
					<p>{t("premiumText")}</p>
				</div>
			</div>
		</section>
	)
}

export default PlanComparisonTable;
