module.exports = {
    common: {
        REQUIRED: "Dieses Feld ist erforderlich",
        MAX_LENGTH: "Max. {{maxLength}} Zeichen",
        MIN_LENGTH: "Min. {{minLength}} Zeichen",
        MAX_LENGTH_DIGIT: "Max. {{maxLength}} Ziffern",
        MIN_LENGTH_DIGIT: "Min. {{minLength}} Ziffern",
        COMPARE: "Der Wiederholungswert muss gleich sein",
        DATE: "Kein gültiges Datum",
        EXACTLY_LENGTH: "Genau {{exactlyLength}} Zeichen",
        EMAIL: "Keine gültige E-Mail-Adresse",
        BACKEND_VALIDATION_ERROR: "Es liegt ein Serverfehler vor",
        ZIPCODE: "{{length}} und nur Zahlen",
        SWEDISH_ZIPCODE: "{{length}} und nur Zahlen",
        PASSWORD: "Min. 8 Zeichen, Zahlen und Buchstaben"
    },
    USER_ALREADY_REGISTERED: "Wir können nicht fortfahren, da Ihre E-Mail-Adresse bereits registriert ist",
    REGISTRATION_FAILED: "Bei der Registrierung ist ein technischer Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
    validationMethods: {
        "required": {
            message: "Dieses Feld ist erforderlich.",
        },
        "email": {
            message: "Ungültige E-Mail-Adresse",
        },
        "matching_email_values": {
            message_mail: "Ungültige E-Mail-Adresse",
            message_not_same: "Die E-Mail-Adressen müssen gleich sein."
        },
        "password": {
            message: "Ungültiges Passwort",
            message_password: "Ungültiges Passwort",
            message_password_lengths: "Min. 8 Zeichen, Zahlen und Buchstaben",
            message_not_same: "Die Passwörter müssen gleich sein."
        },
        "loginError": {
            message: "Anmeldename oder Passwort sind falsch"
        },
        "missingEmail": {
            message: "E-Mail fehlt"
        },
    },
    optionalValue: "optional"
}
